import { createSlice } from "@reduxjs/toolkit";

const projectSlice = createSlice({
    name: "projects",
    initialState: {
        list: []
    },
    reducers: {
        listProjects: (state, action) => {
            state.list = action.payload;
        }
    }
});

export const { listProjects } = projectSlice.actions;

export default projectSlice.reducer;

export const listDataProjects = (state) => state.projects;