import { useState } from "react";

import {
    FormControl,
    FormLabel,
    Input,
    InputRightElement,
    InputGroup,
    Select,
    Icon,
    Text,
    HStack,
    Button,
    Tooltip,
    InputRightAddon,
    Link,
} from "@chakra-ui/react";
import { AiOutlineFileAdd } from "react-icons/ai";
import { useGetProjectsQuery } from "../projects/projectsApiSlice";
import { useGetUsersQuery } from "../users/usersApiSlice";
import { useGetTasksKoorQuery } from "./tasksKoorApiSlice"
import { pathFile, uploadImage } from "../../utils/Functions";
import useAuth from "../../hooks/useAuth";
import { ROLES } from "../../config/roles";
import { Jabatan, KategoriReport } from "../../utils/Constant";
import { InfoOutlineIcon } from "@chakra-ui/icons";

const NewTaskKoorForm = () => {
  const { username: user, email, roles, id, name } = useAuth();

    const { data: listProjects } = useGetProjectsQuery("projectsList", {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
    });

    const { data: listUsers } = useGetUsersQuery("usersList", {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
    });

    const { data: listTasks } = useGetTasksKoorQuery("tasksKoorList", {
        pollingInterval: 15000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
    });

    const listProjectsOptions = listProjects?.ids.map((projectId) => {
        const { project, location, type } = listProjects.entities[projectId];
        return {
          id: projectId,
          label: location,
          value: project,
          type,
        };
      });
    
      const verifyListUsers = listUsers?.ids
      .filter((userId) => listUsers.entities[userId].roles[0] === ROLES.Koordinator)
      .map((userId) => {
        const { username } = listUsers.entities[userId];
    
        return {
          id: userId,
          label: username.charAt(0).toUpperCase() + username.slice(1),
          value: username,
        };
      });
    
      const listUsersOptions = verifyListUsers?.filter(
        (data) => data.value !== user
      );

      const [payload, setPayload] = useState({
        taskKoorName: "",
        projectName: "",
        assignToKoor: "",
        statusTaskKoor: "",
        originatorPIC: {
            id,
            name,
            email,
            roles,
        },
        kategoriReport: "",
        faktorPenyebab: "",
        permintaan: "",
        evaluasi: "",
        rencanaKebutuhanLaporan: "",
        attachment: [],
        namaPemohon: "",
        jabatan: "",
        email: "",
        phone: "",
      });

    const [assignToKoor, setAssignToKoor] = useState([]);
    const [isError, setIsError] = useState(false);
    const [projectSelected, setProjectSelected] = useState({});
    const [kategoriReportSelected, setKategoriReportSelected] = useState('');
    const [jabatanSelected, setJabatanSelected] = useState('');
    const [attachment, setAttachment] = useState([])

    const handleRemoveAttachment = (index) => {
      setAttachment((prev) => prev.filter((_, i) => i !== index))
    }

    return (
        <>
          <FormControl id="taskKoorName" isRequired mt="14px">
            <FormLabel>Report Name</FormLabel>
            <Input
              mt="12px"
              name="taskKoorName"
              type="text"
              placeholder="Report Name"
              value={payload.taskKoorName}
              onChange={(e) =>
                setPayload((prev) => ({
                  ...prev,
                  taskKoorName: e.target.value,
                }))
              }
            />
          </FormControl>
          <FormControl id="projectName" isRequired mt="14px">
            <FormLabel>Project Name</FormLabel>
            <Select
              id={projectSelected.type}
              data-alt={projectSelected.label}
              mt="12px"
              name="projectName"
              variant="outline"
              placeholder="Select Project"
              value={projectSelected.value}
              onChange={(e) =>{
                const selectedProject = listProjectsOptions.find(project => project.value === e.target.value);
                setProjectSelected(selectedProject);
              }}
            >
              {listProjectsOptions?.map((project) => (
                <option key={project.id} value={project.value}>
                  {project.value}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl id="assignToKoor" isRequired mt="14px">
            <FormLabel>Assign To Koor</FormLabel>
              <HStack
                alignItems={"center"}
                justifyContent={"space-between"}
                mt="12px"
              >
                <Select
                  name="assignToKoor"
                  variant="outline"
                  placeholder="Select User"
                  value={assignToKoor.value}
                  onChange={(e) => {
                    const selectedUser = listUsersOptions.find(user => user.value === e.target.value);
                    const newAssignTo = [...assignToKoor];
                    newAssignTo[0] = {
                      ...newAssignTo[0],
                      id: selectedUser.id,
                      value: selectedUser.value,
                    };
                    setAssignToKoor(newAssignTo);
                    setIsError(false);
                  }}
                >
                  {listUsersOptions?.map((user) => (
                    <option key={user.id} value={user.value}>
                      {user.label}
                    </option>
                  ))}
                </Select>
              </HStack>
          </FormControl>
          <FormControl id="kategoriReport" isRequired mt="14px" mb="14px">
            <FormLabel>Kategori Report</FormLabel>
            <Select
              id={kategoriReportSelected}
              data-alt={kategoriReportSelected}
              mt="12px"
              name="kategoriReport"
              variant="outline"
              placeholder="Select Kategori Report"
              value={kategoriReportSelected}
              onChange={(e) =>{
                setKategoriReportSelected(e.target.value);
              }}
            >
              {KategoriReport?.map((kategori, i) => (
                <option key={i++} value={kategori}>
                  {kategori}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl id="faktorPenyebab" isRequired mb="14px">
            <FormLabel>
              Faktor Penyebab
              <Tooltip
                    label="Tuliskan faktor penyebab dari report yang akan dibuat"
                    aria-label="A tooltip"
                    placement='right'
                >
                    <InfoOutlineIcon
                        ml={2}
                        color="gray.500"
                        w={4}
                        h={4}
                        cursor="pointer"
                        _hover={{ color: "gray.700" }}
                    />
              </Tooltip>
            </FormLabel>
            <InputGroup mt="12px">
              <Input
                name="faktorPenyebab"
                type="text"
                placeholder="Faktor Penyebab"
                value={payload.faktorPenyebab}
                onChange={(e) =>
                  setPayload((prev) => ({
                    ...prev,
                    faktorPenyebab: e.target.value,
                  }))
                }
              />
            </InputGroup>
          </FormControl>
          <FormControl id="permintaan" isRequired mb="14px">
            <FormLabel>
              Permintaan
              <Tooltip
                    label="Tuliskan permintaan dari report yang akan dibuat"
                    aria-label="A tooltip"
                    placement='right'
                >
                    <InfoOutlineIcon
                        ml={2}
                        color="gray.500"
                        w={4}
                        h={4}
                        cursor="pointer"
                        _hover={{ color: "gray.700" }}
                    />
              </Tooltip>
            </FormLabel>
            <InputGroup mt="12px">
              <Input
                name="permintaan"
                type="text"
                placeholder="Permintaan"
                value={payload.permintaan}
                onChange={(e) =>
                  setPayload((prev) => ({
                    ...prev,
                    permintaan: e.target.value,
                  }))
                }
              />
            </InputGroup>
          </FormControl>
          <FormControl id="evaluasi" isRequired mb="14px">
            <FormLabel>
              Evaluasi
              <Tooltip
                    label="Tuliskan evaluasi dari report yang akan dibuat"
                    aria-label="A tooltip"
                    placement='right'
                >
                    <InfoOutlineIcon
                        ml={2}
                        color="gray.500"
                        w={4}
                        h={4}
                        cursor="pointer"
                        _hover={{ color: "gray.700" }}
                    />
              </Tooltip>
            </FormLabel>
            <InputGroup mt="12px">
              <Input
                name="evaluasi"
                type="text"
                placeholder="Evaluasi"
                value={payload.evaluasi}
                onChange={(e) =>
                  setPayload((prev) => ({
                    ...prev,
                    evaluasi: e.target.value,
                  }))
                }
              />
            </InputGroup>
          </FormControl>
          <FormControl id="rencanaKebutuhanLaporan" isRequired mb="14px">
            <FormLabel>
              Rencana Kebutuhan Laporan
              <Tooltip
                    label="Tuliskan Rencana penyebab dari report yang akan dibuat"
                    aria-label="A tooltip"
                    placement='right'
                >
                    <InfoOutlineIcon
                        ml={2}
                        color="gray.500"
                        w={4}
                        h={4}
                        cursor="pointer"
                        _hover={{ color: "gray.700" }}
                    />
              </Tooltip>
            </FormLabel>
            <InputGroup mt="12px">
              <Input
                name="rencanaKebutuhanLaporan"
                type="text"
                placeholder="Rencana Kebutuhan Laporan"
                value={payload.rencanaKebutuhanLaporan}
                onChange={(e) =>
                  setPayload((prev) => ({
                    ...prev,
                    rencanaKebutuhanLaporan: e.target.value,
                  }))
                }
              />
            </InputGroup>
          </FormControl>
          <FormControl id="attachment" mb={4}>
            <FormLabel>
                Attachment
                <Tooltip
                    label="Tambahkan lampiran file yang diperlukan (contoh: Gambar, Dokumen, dll)"
                    aria-label="A tooltip"
                    placement='right'
                >
                    <InfoOutlineIcon
                        ml={2}
                        color="gray.500"
                        w={4}
                        h={4}
                        cursor="pointer"
                        _hover={{ color: "gray.700" }}
                    />
                </Tooltip>
            </FormLabel>
            {payload.attachment.length >= 1 ? (
                <>
                    {payload?.attachment?.map((file, index) => (
                        <HStack
                            spacing={4}
                            align="start"
                            justifyContent={"space-between"}
                            mt={4}
                        >
                            <InputGroup>
                                <Link href={pathFile(file)} isExternal>{file}</Link>
                                <InputRightAddon>
                                    <Icon as={AiOutlineFileAdd} />
                                </InputRightAddon>
                            </InputGroup>
                            <Button
                                bg="red.500"
                                onClick={() => handleRemoveAttachment(index)}
                            >X</Button>
                        </HStack>
                    ))}
                </>
            ) : (
                <>
                    {attachment?.map((file, index) => (
                        <HStack
                            spacing={4}
                            align="start"
                            justifyContent={"space-between"}
                            mt={4}
                        >
                            <InputGroup>
                                <Input
                                    id="attachment"
                                    name="attachment"
                                    type="file"
                                    placeholder="Attachment"
                                    autoFocus
                                    cursor={"pointer"}
                                    onChange={(e) => {
                                        const files = uploadImage(e.target.files[0] ?? null)
                                            .then((res) => {
                                                localStorage.setItem(`attachment_taskskoor${index}`, res)
                                                return setAttachment((prev) => [...prev, res])
                                            })
                                            .catch((err) => {
                                                console.error(err)
                                            })

                                        return files;
                                    }}
                                />
                                <InputRightAddon>
                                    <Icon as={AiOutlineFileAdd} />
                                </InputRightAddon>
                            </InputGroup>
                            <Button
                                colorScheme="whatsapp"
                                onClick={() => {
                                    setAttachment((prev) => [...prev, ''])
                                }}
                            >+</Button>
                            <Button
                                bg="red.500"
                                textColor={"white"}
                                _hover={{ bg: "red.600" }}
                                onClick={() => handleRemoveAttachment(index)}
                            >x</Button>
                        </HStack>
                    ))}
                </>
            )}
            {(attachment.length === 0 && payload.attachment.length === 0) && (
                <HStack
                    spacing={4}
                    align="start"
                    justifyContent={"space-between"}
                    mt={4}
                >
                    <Text
                        fontSize={"14px"}
                        textOverflow={"ellipsis"}
                        overflow={"hidden"}
                        color={"gray.500"}
                    >
                        Tambahkan attachment
                    </Text>
                    <Button
                        colorScheme="whatsapp"
                        onClick={() => {
                            setAttachment((prev) => [...prev, ''])
                        }}
                    >+</Button>
                </HStack>
            )}
        </FormControl>
          <FormControl id="namaPemohon" isRequired mb="14px">
            <FormLabel>Nama Pemohon</FormLabel>
            <InputGroup mt="12px">
              <Input
                name="namaPemohon"
                type="text"
                placeholder="Nama Pemohon"
                value={payload.namaPemohon}
                onChange={(e) =>
                  setPayload((prev) => ({
                    ...prev,
                    namaPemohon: e.target.value,
                  }))
                }
              />
              <InputRightElement>
                <Icon as={AiOutlineFileAdd} />
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <FormControl id="jabatan" isRequired mt="14px" mb="14px">
            <FormLabel>Jabatan</FormLabel>
            <Select
              id={jabatanSelected}
              data-alt={jabatanSelected}
              mt="12px"
              name="jabatan"
              variant="outline"
              placeholder="Select Jabatan"
              value={jabatanSelected}
              onChange={(e) =>{
                setJabatanSelected(e.target.value);
              }}
            >
              {Jabatan?.map((kategori, i) => (
                <option key={i++} value={kategori}>
                  {kategori}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl id="email" isRequired mb="14px">
            <FormLabel>Email</FormLabel>
            <InputGroup mt="12px">
              <Input
                name="email"
                type="text"
                placeholder="Email"
                value={payload.email}
                onChange={(e) =>
                  setPayload((prev) => ({
                    ...prev,
                    email: e.target.value,
                  }))
                }
              />
              <InputRightElement>
                <Icon as={AiOutlineFileAdd} />
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <FormControl id="phone" isRequired mb="14px">
            <FormLabel>No. HP</FormLabel>
            <InputGroup mt="12px">
              <Input
                name="phone"
                type="text"
                placeholder="No. HP"
                value={payload.phone}
                onChange={(e) =>
                  setPayload((prev) => ({
                    ...prev,
                    phone: e.target.value,
                  }))
                }
              />
              <InputRightElement>
                <Icon as={AiOutlineFileAdd} />
              </InputRightElement>
            </InputGroup>
          </FormControl>
        </>
      );
};

export default NewTaskKoorForm;