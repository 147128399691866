// React core and custom hooks
import { useState, useEffect } from "react";

// Chakra Components and Icons
import {
  FormControl,
  FormLabel,
  Input,
  InputRightElement,
  InputGroup,
  Button,
  Select,
  InputRightAddon,
  Icon,
} from "@chakra-ui/react";
import { AiOutlineFileAdd } from "react-icons/ai";

// Proprietary Components
import { ROLES } from "../../config/roles";
import { uploadImage } from "../../utils/Functions";

const USER_REGEX = /^[A-z]{3,20}$/;
const PWD_REGEX = /^[A-z0-9!@#$%]{4,12}$/;
const PHONE_REGEX = /^[0-9]{10,12}$/;
const EMAIL_REGEX = /^[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z]{2,}$/;

const NewUserForm = () => {
  const [show, setShow] = useState({
    pw: false,
    cpw: false,
  });

  const [payload, setPayload] = useState({
    name: "",
    username: "",
    validUsername: false,
    password: "",
    confirmPassword: "",
    validPassword: false,
    roles: ["Engineering"],
    phone: "",
    validPhone: false,
    image: null,
    validImage: false,
    email: "",
    validEmail: false,
  });

  useEffect(() => {
    setPayload((prev) => {
      return {
        ...prev,
        validUsername: USER_REGEX.test(prev.username),
      };
    });
  }, [payload.username]);

  useEffect(() => {
    setPayload((prev) => {
      return {
        ...prev,
        validPassword: PWD_REGEX.test(prev.password),
      };
    });
  }, [payload.password]);

  useEffect(() => {
    setPayload((prev) => {
      return {
        ...prev,
        validPhone: PHONE_REGEX.test(prev.phone),
      };
    });
  }, [payload.phone]);

  useEffect(() => {
    setPayload((prev) => {
      return {
        ...prev,
        validEmail: EMAIL_REGEX.test(prev.email),
      };
    });
  }, [payload.email]);

  return (
    <>
      <FormControl>
        <FormLabel>Name</FormLabel>
        <Input
          id="name"
          placeholder="Name"
          autoFocus
          type="text"
          autoComplete="off"
          required
          value={payload.name}
          onChange={(e) => {
            setPayload((prev) => {
              return {
                ...prev,
                name: e.target.value,
              };
            });
          }}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Username</FormLabel>
        <Input
          id="username"
          placeholder="username"
          autoFocus
          type="text"
          autoComplete="off"
          required
          value={payload.username}
          onChange={(e) => {
            setPayload((prev) => {
              return {
                ...prev,
                username: e.target.value,
              };
            });
          }}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Email</FormLabel>
        <Input
          id="email"
          placeholder="Email"
          autoFocus
          type="email"
          autoComplete="off"
          required
          value={payload.email}
          onChange={(e) => {
            setPayload((prev) => {
              return {
                ...prev,
                email: e.target.value,
              };
            });
          }}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Password</FormLabel>
        <InputGroup size="md">
          <Input
            id="password"
            pr="4.5rem"
            type={show.pw ? "text" : "password"}
            placeholder="Enter password"
            autoComplete="off"
            required
            value={payload.password}
            onChange={(e) => {
              setPayload((prev) => {
                return {
                  ...prev,
                  password: e.target.value,
                };
              });
            }}
          />
          <InputRightElement width="4.5rem">
            <Button
              h="1.75rem"
              size="sm"
              onClick={() => {
                setShow((prev) => {
                  return {
                    ...prev,
                    pw: !prev.pw,
                  };
                });
              }}
            >
              {show.pw ? "Hide" : "Show"}
            </Button>
          </InputRightElement>
        </InputGroup>
      </FormControl>
      <FormControl>
        <FormLabel>Confirm password</FormLabel>
        <InputGroup size="md">
          <Input
            pr="4.5rem"
            type={show.cpw ? "text" : "password"}
            placeholder="Confirm password"
            autoComplete="off"
            required
            value={payload.confirmPassword}
            onChange={(e) => {
              setPayload((prev) => {
                return {
                  ...prev,
                  confirmPassword: e.target.value,
                };
              });
            }}
          />
          <InputRightElement width="4.5rem">
            <Button
              h="1.75rem"
              size="sm"
              onClick={() => {
                setShow((prev) => {
                  return {
                    ...prev,
                    cpw: !prev.cpw,
                  };
                });
              }}
            >
              {show.cpw ? "Hide" : "Show"}
            </Button>
          </InputRightElement>
        </InputGroup>
      </FormControl>
      <FormControl>
        <FormLabel>No. Telp / WA</FormLabel>
        <Input
          id="phone"
          placeholder="No. Telp / WA"
          autoFocus
          type="number"
          autoComplete="off"
          required
          value={payload.phone}
          onChange={(e) => {
            setPayload((prev) => {
              return {
                ...prev,
                phone: e.target.value,
              };
            });
          }}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Photo</FormLabel>
        <InputGroup>
          <Input
            id="image"
            name="image"
            accept="image/*"
            autoFocus
            type="file"
            autoComplete="off"
            required
            cursor={"pointer"}
            onChange={(e) => {
              const images = uploadImage(e.target?.files[0] ?? null)
                .then((image) => {
                  localStorage.setItem(`profile_image_${payload.username}`, image)
                  setPayload((prev) => {
                      return {
                          ...prev,
                          image,
                      };
                  });
                }).catch((err) => console.err(err))

              return images
            }}
        />
          <InputRightAddon>
            <Icon as={AiOutlineFileAdd} />
          </InputRightAddon>
        </InputGroup>
      </FormControl>
      <FormControl>
        <FormLabel>Role</FormLabel>
        <Select
          id="role"
          placeholder="Select role"
          autoComplete="off"
          required
          value={payload.roles[0]}
          onChange={(e) => {
            setPayload((prev) => {
              return {
                ...prev,
                roles: [e.target.value],
              };
            });
          }}
        >
          {Object.values(ROLES).map((val, i) => {
            return (
              <option value={val} key={i}>
                {val}
              </option>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
};

export default NewUserForm;
