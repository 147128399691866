import { Box, Button, HStack, Image, Spacer, Stack, Table, Tbody, Td, Text, Th, Thead, Tr, VStack, useBreakpointValue, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useGetTasksByIdQuery } from '../tasks/tasksApiSlice';
import { converTime, getPDFTasks } from '../../utils/Functions';
import UpdateDownload from './common/update.modal.fk';
import { PulseLoader } from 'react-spinners';

const imgLogo = require('../../img/logo_wika_text.png');
const iconVerified = require('../../img/icon-verified.png');
const bannerVerified = require('../../img/large-banners-verified.png');
const bgWaves = require('../../img/bg-waves.png');

const VerifiedScreen = () => {
  const [updatedDownload, setUpdatedDownload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const urlPath = window.location.pathname;
  const id = urlPath.split("/verify/")[1];

  const {
    data: tasks,
    isSuccess,
  } = useGetTasksByIdQuery(id, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const stackDirection = useBreakpointValue({ base: 'column', md: 'row' });
  const imageSize = useBreakpointValue({ base: '80px', md: '100px' });
  const bannerWidth = useBreakpointValue({ base: '100%', md: '500px' });
  const fontSize = useBreakpointValue({ base: 'sm', md: 'xl' });
  const fontSizeHead = useBreakpointValue({ base: 'lg', md: 'xl' });
  const tableFontSize = useBreakpointValue({ base: 'xs', md: 'sm' });
  const marginTable = useBreakpointValue({ base: 4, md: 4 });
  const tablePadding = useBreakpointValue({ base: 1, md: 4 });

  const handleDownloadReport = async () => {
    setIsLoading(true);
    const toastId = toast({
      title: 'Downloading...',
      description: 'Your report is being downloaded.',
      status: 'info',
      duration: null,
      isClosable: true,
    });

    try {
      await getPDFTasks();
      toast.update(toastId, {
        title: 'Download Complete',
        description: 'Your report has been downloaded.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast.update(toastId, {
        title: 'Download Failed',
        description: 'There was an error downloading your report.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (isSuccess) {
    const {
      taskNumber,
      taskName,
      projectName,
      projectLocation,
      report,
      updatedAt,
      historyDownload,
    } = tasks;

    const mappedManager = report?.find((item) => item.updatedBy.role === "Manager" && [ "Approved", "Revision I", "Revision II"].includes(item.status));
    const mappedKoor = report?.find((item) => item.updatedBy.role === "Koordinator" && [ "Approved", "Revision I", "Revision II"].includes(item.status));
    const mappedEngineer = report?.find((item) => item.updatedBy.role === "Engineering" && item.description === "Task Report Updated");

    return (
     <>
      <Box
        bgImage={`url(${bgWaves})`}
        bgSize="cover"
        bgRepeat="no-repeat"
        bgPos="center"
        p={4}
        width="full"
        minH={window.innerHeight}
      >
        <HStack>
          <Image
            src={imgLogo}
            alt="logo-wika"
            width={imageSize}
            height={imageSize}
            objectFit="cover"
          />
          <Text
            fontSize={"xl"}
            as="b"
            ml={marginTable}
          >Engineering Report System</Text>
        </HStack>

        <Spacer h={8} />

        <Stack
          direction={stackDirection}
          justify="space-between"
          align="center"
          p={4}
          spacing={4}
        >
          <VStack
            spacing={4}
            align="center"
            justify
          >
            <Image
              src={iconVerified}
              alt="icon-verified"
              width="80px"
              height="80px"
              objectFit="cover"
            />
            <Text
              fontSize={fontSizeHead}
              as="b"
              textAlign="center"
            >Data sudah terverifikasi dan disahkan secara digital</Text>
            <VStack>
              <VStack>
                <Table
                  size="sm"
                  variant="ghost"
                >
                  <Thead>
                    <Tr>
                      <Th fontSize={tableFontSize} p={tablePadding}>Report Number</Th>
                      <Th fontSize={tableFontSize} p={tablePadding}>Judul Report</Th>
                      <Th fontSize={tableFontSize} p={tablePadding}>Nama Project</Th>
                      <Th fontSize={tableFontSize} p={tablePadding}>Lokasi Project</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td fontSize={tableFontSize} p={tablePadding}>
                        <VStack>
                          <Text>{taskNumber}</Text>
                        </VStack>
                      </Td>
                      <Td fontSize={tableFontSize} p={tablePadding}>
                        <VStack>
                          <Text>{taskName}</Text>
                        </VStack>
                      </Td>
                      <Td fontSize={tableFontSize} p={tablePadding}>
                        <VStack>
                          <Text>{projectName}</Text>
                        </VStack>
                      </Td>
                      <Td fontSize={tableFontSize} p={tablePadding}>
                        <VStack>
                          <Text>{projectLocation}</Text>
                        </VStack>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </VStack>
              <VStack>
              <Table
                  size="sm"
                  variant="ghost"
                >
                  <Thead>
                    <Tr>
                      <Th fontSize={tableFontSize} p={tablePadding}>Manager Engineer</Th>
                      <Th fontSize={tableFontSize} p={tablePadding}>Koor Engineer</Th>
                      <Th fontSize={tableFontSize} p={tablePadding}>Engineer</Th>
                      <Th fontSize={tableFontSize} p={tablePadding}>Tanggal Disahkan</Th>
                    </Tr>
                  </Thead>
                  <Tbody
                    justifyContent="start"
                    alignItems="start"
                  >
                    <Tr>
                      <Td fontSize={tableFontSize} p={tablePadding}>
                        <VStack>
                          <Text>
                          {mappedManager?.updatedBy?.name ?? ""}
                          </Text>
                        </VStack>
                      </Td>
                      <Td fontSize={tableFontSize} p={tablePadding}>
                        <VStack>
                          <Text>
                            {mappedKoor?.updatedBy?.name ?? ""}
                          </Text>
                        </VStack>
                      </Td>
                      <Td fontSize={tableFontSize} p={tablePadding}>
                        <VStack>
                          <Text>{mappedEngineer?.updatedBy?.name ?? ""}</Text>
                        </VStack>
                      </Td>
                      <Td fontSize={tableFontSize} p={tablePadding}>
                        <VStack>
                          <Text>{converTime(updatedAt)}</Text>
                        </VStack>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </VStack>
              {Array.isArray(historyDownload) && historyDownload && historyDownload.length > 0 && (
                <VStack>
                <Table
                    size="sm"
                    variant="ghost"
                  >
                    <Thead>
                      <Tr>
                        <Th fontSize={tableFontSize} p={tablePadding}>Jumlah Download</Th>
                        <Th fontSize={tableFontSize} p={tablePadding}>History Download</Th>
                      </Tr>
                    </Thead>
                    <Tbody
                      justifyContent="start"
                      alignItems="start"
                    >
                      <Tr>
                        <Td fontSize={tableFontSize} p={tablePadding}>
                          <VStack>
                            <Text>{historyDownload && historyDownload?.length ? '' : historyDownload?.length}</Text>
                          </VStack>
                        </Td>
                        <Td fontSize={tableFontSize} p={tablePadding}>
                          {historyDownload && historyDownload.map((item, index) => (
                            <VStack key={index} mb={2} mt={2}>
                              <Text>{item.name}</Text>
                            </VStack>
                          ))}
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </VStack>
              )}
            </VStack>
            <HStack>
              <Button
                colorScheme="whatsapp"
                size="md"
                onClick={handleDownloadReport}
                isLoading={isLoading}
                loadingText="Downloading"
                disabled={isLoading}
              >
                Download Report
              </Button>
            </HStack>
          </VStack>
          <VStack>
            <Image
              src={bannerVerified}
              alt="banner-verified"
              width={bannerWidth}
              height="auto"
              objectFit="cover"
            />
          </VStack>
        </Stack>
      </Box>
      <UpdateDownload
        tasks={tasks ?? {}}
        isUpdate={updatedDownload}
        setIsUpdate={setUpdatedDownload}
      />
     </>
    )
  }

  return (
    <Box
      bgImage={`url(${bgWaves})`}
      bgSize="cover"
      bgRepeat="no-repeat"
      bgPos="center"
      p={4}
      width="full"
      minH={window.innerHeight}
    >
      <HStack>
        <Image
          src={imgLogo}
          alt="logo-wika"
          width={imageSize}
          height={imageSize}
          objectFit="cover"
        />
        <Text
          fontSize={fontSize}
          as="b"
        >Engineering Report System</Text>
      </HStack>

      <Spacer h={8} />

      <Stack
        direction={stackDirection}
        justify="space-between"
        align="center"
        p={4}
        spacing={4}
      >
        <VStack
          spacing={4}
          align="center"
          justify
        >
          <Image
            src={iconVerified}
            alt="icon-verified"
            width="80px"
            height="80px"
            objectFit="cover"
          />
          <Text
            fontSize={fontSize}
            as="b"
            textAlign="center"
          >Menunggu Memuat Data Verifikasi</Text>
        </VStack>
        <VStack>
          <PulseLoader color={"#2BACE3"} />
        </VStack>
        <VStack>
          <Image
            src={bannerVerified}
            alt="banner-verified"
            width={bannerWidth}
            height="auto"
            objectFit="cover"
          />
        </VStack>
      </Stack>
    </Box>
  );
}

export default VerifiedScreen;