// React core and custom hooks
import { Tr, Td, Center, Button, Text } from "@chakra-ui/react";

const StandardTdComponent = ({ children, textAlign = "center", ...props }) => {
  return (
    <Td
      textAlign={textAlign}
      fontSize={"14px"}
      textOverflow={"ellipsis"}
      overflow={"hidden"}
      {...props}
    >
      {children}
    </Td>
  );
};

export default StandardTdComponent;
