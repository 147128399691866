import { Outlet } from "react-router-dom";
import DashHeader from "./DashHeader";
// import DashFooter from "./DashFooter";
import DashSidebar from "./DashSidebar/DashSidebar";
import { Flex, Box } from "@chakra-ui/react";

const DashLayout = () => {
  return (
    <Flex height="100svh" overflowX={"hidden"} overflowY={"auto"}>
      <Box
        bg="#f1f5f9"
        width={{ base: "100%", md: "250px" }}
        position={"sticky"}
        top={0}
        left={0}
        zIndex={999}
      >
        <DashSidebar />
      </Box>

      <Flex flexDirection="column" flex="1">
        {/* Top Navigator */}
        <Box position={"sticky"} top={0} left={0} bg={"#f1f5f9"} zIndex={999}>
          <DashHeader />
        </Box>

        {/* Main Content */}
        <Box flex="1" p={4} bg="#f1f5f9">
          {/* CONTENT */}
          <Outlet />
        </Box>
      </Flex>
    </Flex>
  );
};
export default DashLayout;
