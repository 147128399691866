import React from 'react'
import CreateModal from '../../../components/BodyTemplate/CreateModal';
import { Text } from '@chakra-ui/react';
import { useDeleteUserMutation } from '../usersApiSlice';

const DeleteUser = ({
  userName,
  id,
  isDelete,
  setIsDelete
}) => {
  const [deleteUser, {
    isSuccess: isDelSuccess,
    isError: isDelError,
    error: delerror
}] = useDeleteUserMutation()

  const onDelete = async () => {
    await deleteUser({ id });

    setIsDelete(false);
  };

  return (
    <CreateModal
      modalTitle={"Delete Project"}
      isOpen={isDelete}
      onClose={() => {
      setIsDelete(false);
      }}
      buttonPrimaryText={"Delete"}
      onSubmit={onDelete}
  >
      <Text
      fontSize={"18px"}
      >
        Apa kamu yakin ingin menghapus akun {userName}?
      </Text>
    </CreateModal>
  )
}

export default React.memo(DeleteUser)