// React core and custom hooks
import { useState, useEffect } from "react";

// Chakra Components and Icons
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Text,
} from "@chakra-ui/react";
import { uploadImage } from "../../utils/Functions";

const EMAIL_REGEX = /^[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z]{2,}$/;

const NewProjectForm = () => {
  const [payload, setPayload] = useState({
    project: "",
    location: "",
    type: "",
    manager: "",
    kasi: "",
    pic: [],
    email: "",
    validEmail: false,
    ksoImage: null,
  });
  const [pic, setPic] = useState([]);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setPayload((prev) => {
      return {
        ...prev,
        validEmail: EMAIL_REGEX.test(prev.email),
      };
    });
  }, [payload.email]);

  return (
    <>
        <FormControl id="project" isRequired>
        <FormLabel>Project Name</FormLabel>
        <Input
            type="text"
            value={payload.project}
            onChange={(e) => setPayload({ ...payload, project: e.target.value })}
        />
        </FormControl>
        <FormControl id="location" isRequired>
        <FormLabel>Project Location</FormLabel>
        <Input
            type="text"
            value={payload.location}
            onChange={(e) => setPayload({ ...payload, location: e.target.value })}
        />
        </FormControl>
        <FormControl id="type" isRequired>
        <FormLabel>Project Type</FormLabel>
        <Input
            type="text"
            value={payload.type}
            onChange={(e) => setPayload({ ...payload, type: e.target.value })}
        />
        </FormControl>
        <FormControl id="manager" isRequired>
        <FormLabel>Project Manager</FormLabel>
        <Input
            type="text"
            value={payload.manager}
            onChange={(e) => setPayload({ ...payload, manager: e.target.value })}
        />
        </FormControl>
        <FormControl id="kasi" isRequired>
        <FormLabel>Kasi Engineering</FormLabel>
        <Input
            type="text"
            value={payload.kasi}
            onChange={(e) => setPayload({ ...payload, kasi: e.target.value })}
        />
        </FormControl>
        <FormControl id="pic" isRequired mt="14px">
          <FormLabel>Contact PIC Project</FormLabel>
          {pic.map((person, index) => (
            <HStack
              key={index}
              alignItems={"center"}
              justifyContent={"space-between"}
              mt="12px"
            >
              <Input
                name="picName"
                type="text"
                placeholder="PIC Name"
                value={person.name}
                onChange={(e) => {
                  const newPIC = [...pic];
                  newPIC[index].name = e.target.value;
                  setPic(newPIC);
                  setIsError(false);
                }}
              />
              <Input
                name="picPhone"
                type="number"
                placeholder="PIC Phone/WA"
                value={person.phone}
                onChange={(e) => {
                  const newPIC = [...pic];
                  newPIC[index].phone = e.target.value;
                  setPic(newPIC);
                  setIsError(false);
                }}
              />
              <Button
                colorScheme="whatsapp"
                size={"sm"}
                onClick={() => {
                  if (pic.length > 2) {
                    setIsError(true);
                    return;
                  }
                  setPic([...pic, { name: "", phone: "" }]);
                }}
                disabled={isError}
              >
                +
              </Button>
            </HStack>
          ))}
          {pic.length === 0 && (
            <HStack
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Text
                fontSize={"14px"}
                textOverflow={"ellipsis"}
                overflow={"hidden"}
                color={"gray.500"}
              >Tambahkan PIC</Text>
              <Button
                colorScheme="whatsapp"
                size={"sm"}
                onClick={() => {
                  setPic([{ name: "", phone: "" }]);
                }}
              >
                +
              </Button>
            </HStack>
          )}
          {isError && (
            <Text
              fontSize={"14px"}
              textOverflow={"ellipsis"}
              overflow={"hidden"}
              color={"red.500"}
            >
              Max PIC is 3
            </Text>
          )}
        </FormControl>
        <FormControl id="email" isRequired>
        <FormLabel>Email</FormLabel>
        <Input
            type="email"
            value={payload.email}
            onChange={(e) => setPayload({ ...payload, email: e.target.value })}
        />
        </FormControl>
        <FormControl id="ksoImage" mt={4}>
          <FormLabel>Logo Laporan</FormLabel>
          <Input
            id="ksoImage"
            name="ksoImage"
            accept="image/*"
            autoFocus
            type="file"
            curosr={"pointer"}
            onChange={(e) => {
              const images = uploadImage(e.target?.files[0] ?? null)
                .then((image) => {
                  setPayload((prev) => {
                    return {
                      ...prev,
                      ksoImage: image,
                    };
                  });
                }).catch((err) => console.err(err))

              return images
            }}
          />
        </FormControl>
    </>
  );
};

export default NewProjectForm;
