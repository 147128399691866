import { Table, Thead, Tbody, Tr, Th, TableContainer } from "@chakra-ui/react";

const TaskKoorTable = ({ children }) => {
  return (
    <TableContainer>
      <Table layout={"fixed"} width={"full"}>
        <Thead>
          <Tr>
            <Th
              textAlign={"center"}
              fontSize={"sm"}
              color={"gray.400"}
              fontWeight={"medium"}
              width={"8%"}
            >
              No.
            </Th>
            <Th
              textAlign={"left"}
              fontSize={"sm"}
              color={"gray.400"}
              fontWeight={"medium"}
              width={"20%"}
            >
              Task Name
            </Th>
            <Th
              textAlign={"left"}
              fontSize={"sm"}
              color={"gray.400"}
              fontWeight={"medium"}
              width={"15%"}
            >
              Project
            </Th>
            <Th
              textAlign={"center"}
              fontSize={"sm"}
              color={"gray.400"}
              fontWeight={"medium"}
              width={"10%"}
            >
              Status
            </Th>
            <Th
              textAlign={"center"}
              fontSize={"sm"}
              color={"gray.400"}
              fontWeight={"medium"}
              width={"17%"}
            >
              Action
            </Th>
          </Tr>
        </Thead>
        <Tbody>{children}</Tbody>
      </Table>
    </TableContainer>
  );
}

export default TaskKoorTable;