// React core and custom hooks
import { useAddNewTaskMutation, useGetTasksQuery } from "./tasksApiSlice";
import Task from "./Task";
import useAuth from "../../hooks/useAuth";
import PulseLoader from "react-spinners/PulseLoader";

// Chakra Components and Icons

// Proprietary Components
import BodyTemplate from "../../components/BodyTemplate/BodyTemplate";
import TaskTable from "./TaskTable";
import { Button, Flex, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { useState } from "react";
import NewTaskForm from "./NewTaskForm";
import { useGetUsersQuery } from "../users/usersApiSlice";
import { formatPhoneNumber, sendWhatsappDirect } from "../../utils/Functions";
import { TemplateWhatsAppID } from "../../utils/Constant";

const TasksList = () => {
  const { id: idUser, name, username, isManager, isAdmin, isKoordinator, phone, image, status } = useAuth();

  const [addNewTask, {}] = useAddNewTaskMutation();
  const toast = useToast();

  const {
    data: tasks,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetTasksQuery("tasksList", {
    pollingInterval: 15000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const {
    data: listUsers,
  } = useGetUsersQuery("usersList", {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [searchUser, setSearchUser] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const itemsPerPage = 10;

  const filteredListUsers = listUsers?.ids.map((userId) => {
    const { _id, username } = listUsers.entities[userId];

    return {
      _id,
      username
    };
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const toastId = toast({
      title: 'Silahkan tunggu...',
      description: 'Sedang membuat new task.',
      status: 'info',
      duration: null,
      isClosable: true,
      position: 'top',
    });

    const isShare = localStorage.getItem('isShare');

    const {
      taskName,
    } = e.target;

    const project = {
      projectName: e.target.elements.projectName.value,
      projectType: e.target.elements.projectName.id,
      projectLocation: e.target.elements.projectName.dataset.alt,
    }
    let assignRoles = Array.from(e.target.elements.assignRoles)
    let assignTo = Array.from(e.target.elements.assignTo)

    if (assignRoles.length === 0) {
      assignRoles = [
        {
          id: e.target.elements.assignRoles.id,
          name: e.target.elements.assignRoles.alt,
          roles: e.target.elements.assignRoles.value,
        },
      ];
    } else {
      assignRoles = Array.from(e.target.elements.assignRoles)
        .map(input => ({ id: input.id, name: input.alt, roles: input.value }))
        .filter(value => value !== '');
    }

    if (assignTo.length > 3 && assignRoles.length <= 1) {
      assignTo = [assignRoles[0].id];
    } else {
      assignTo = Array.from(e.target.elements.assignTo)
      .map(input => input.value)
      .filter(value => value !== '');

      assignTo = assignTo.map(username => {
        const user = filteredListUsers.find(user => user.username === username);
        return user ? user._id : null;
      }).filter(id => id !== null);
    }

    if (isAdmin) {
      return toast.update(toastId, {
        title: 'Gagal membuat new task',
        description: 'Admin tidak bisa membuat task',
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top',
      });
    }

    const assignReports = assignRoles.map((role) => role.name).join(" & ");

    try {
      await addNewTask({
        taskName: taskName.value,
        projectName: project.projectName,
        projectType: project.projectType,
        projectLocation: project.projectLocation,
        assignTo,
        assignRoles,
        originator: [
          {
            username,
            phone,
            image,
            status: "Open",
          },
        ],
        report: {
          date: new Date().toISOString(),
          description: `Task Assign To Engineers - ${assignReports}`,
          status: "Open",
          updatedBy: {
            role: status,
            name: name,
          },
        }
      });

      toast.update(toastId, {
        title: 'Berhasil membuat new task',
        description: 'Task berhasil dibuat',
        status: 'success',
        duration: 9000,
        isClosable: true,
        position: 'top',
      });
    } catch (error) {
      onClose();
      setLoading(false);

      toast.update(toastId, {
        title: 'Gagal membuat new task',
        description: 'Terjadi kesalahan saat membuat task',
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top',
      });
    } finally {
      onClose();
      setLoading(false);

      if (isShare === 'true') {
        const assignUsersPhone = assignTo.map((userId) => ({
          name: listUsers.entities[userId].name,
          phone: listUsers.entities[userId].phone,
        }));
  
        for (const assignUsers of assignUsersPhone) {
          await sendWhatsappDirect({
            name,
            to: formatPhoneNumber(assignUsers.phone),
            template: TemplateWhatsAppID.sendTaskToEngineer,
            variables: {
              nama_engineer: assignUsers.name,
              nama_koor: name,
              nama_report: taskName.value,
              nama_project: project.projectName,
            },
            file: "unfile",
          });
        }

        toast({
          title: 'Berhasil mengirim Whatsapp',
          description: "Pesan task berhasil dikirimkan ke engineer",
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top',
        })
  
        localStorage.removeItem('isShare');
      }
  
    }

    setLoading(false);
    onClose();
  };

  let content;

  if (isLoading) content = <PulseLoader color={"#2BACE3"} />;

  if (isError && error.data.message !== 'No tasks found') {
    content = <p className="errmsg">{error?.data?.message}</p>;
  }

  if (isSuccess) {
    const { ids, entities } = tasks;

    let filteredIds;
    if (isManager || isAdmin || isKoordinator) {
      filteredIds = [...ids];
    } else {
      filteredIds = ids?.filter(
        (taskId) => entities[taskId].assignRoles.some((taskUser) => taskUser.id === idUser)
      );
    }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredIds.slice(indexOfFirstItem, indexOfLastItem);

    const tableContent =
      currentItems?.length &&
      currentItems.map((taskId, index) => <Task count={index + 1} key={taskId} taskId={taskId} />);

    const totalPages = Math.ceil(filteredIds.length / itemsPerPage);
    const paginationControls = (
      <Flex justify="flex-end" mt={4}>
        {Array.from({ length: totalPages }, (_, index) => (
          <Button
            key={index}
            onClick={() => setCurrentPage(index + 1)}
            mx={1}
            colorScheme={currentPage === index + 1 ? "blue" : "gray"}
          >
            {index + 1 === totalPages ? "Last" : index + 1}
          </Button>
        ))}
      </Flex>
    );

    content = (
      <BodyTemplate
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        documentTitle="Task List"
        pageTitle="Task Engineer"
        searchBarPlaceHolder="task"
        searchValue={searchUser}
        setSearchValue={setSearchUser}
        form={<NewTaskForm />}
        modalTitle="Create New Task"
        onSubmit={onSubmit}
        useCreate={!!isManager || !!isAdmin || !!isKoordinator}
        isShareWhatsapp={true}
      >
        <TaskTable>{tableContent}</TaskTable>
        {totalPages > 1 && paginationControls}
      </BodyTemplate>
    );
  } else if (error && error.data.message === 'No tasks found') {
    content = (
      <BodyTemplate
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        documentTitle="Task List"
        pageTitle="Task Engineer"
        searchBarPlaceHolder="task"
        searchValue={searchUser}
        setSearchValue={setSearchUser}
        form={<NewTaskForm />}
        modalTitle="Create New Task"
        onSubmit={onSubmit}
        useCreate={!!isManager || !!isAdmin || !!isKoordinator}
        isShareWhatsapp={true}
      >
        <Text>No tasks found</Text>
      </BodyTemplate>
    );
  }

  return content;
};
export default TasksList;
