import {
    useCreateTaskKoorMutation,
    useGetTasksKoorQuery,
} from "./tasksKoorApiSlice";
import { useGetUsersQuery } from "../users/usersApiSlice";
import TaskKoor from "./TaskKoor";

import PulseLoader from "react-spinners/PulseLoader";
import BodyTemplate from "../../components/BodyTemplate/BodyTemplate";
import TaskKoorTable from "./TaskKoorTable";
import useAuth from "../../hooks/useAuth";
import { Button, Flex, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { useState } from "react";
import NewTaskKoorForm from "./NewTaskKoorForm";
import { formatPhoneNumber, sendWhatsappDirect } from "../../utils/Functions";
import { TemplateWhatsAppID } from "../../utils/Constant";

const TasksKoorList = () => {
  const {
    id: idUser,
    name,
    isManager,
    isAdmin,
    isKoordinator,
    isPICProject,
    email: emailUser,
    roles,
} = useAuth();

const [addNewTaskKoor, {}] = useCreateTaskKoorMutation();
const toast = useToast();

const {
    data: tasksKoor,
    isLoading,
    isSuccess,
    isError,
    error,
} = useGetTasksKoorQuery("tasksKoorList", {
    // pollingInterval: 15000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
});

const {
    data: listUsers,
  } = useGetUsersQuery("usersList", {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [searchUser, setSearchUser] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const itemsPerPage = 10;


  const filteredListUsers = listUsers?.ids.map((userId) => {
    const { _id, username } = listUsers.entities[userId];

    return {
      _id,
      username
    };
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const toastId = toast({
      title: 'Please wait...',
      description: 'Creating new task.',
      status: 'info',
      duration: null,
      isClosable: true,
      position: 'top',
    });

    const isShare = localStorage.getItem('isShare');

    const {
        taskKoorName,
        faktorPenyebab,
        permintaan,
        evaluasi,
        rencanaKebutuhanLaporan,
        namaPemohon,
        email,
        phone,
    } = e.target;

    let attachmentVerify = [];

    if (e.target.attachment && e.target.attachment.length > 0) {
      for (let i = 0; i < e.target.attachment.length; i++) {
        attachmentVerify = [...attachmentVerify, localStorage.getItem(`attachment_taskskoor${i}`)];
  
        localStorage.removeItem(`attachment_taskskoor${i}`);
      };
    }

    const attachment = attachmentVerify?.filter((item) => item !== null) ?? [];

    const project = {
        projectName: e.target.elements.projectName.value,
    }
    const kategoriReport = e.target.elements.kategoriReport.value
    const jabatan = e.target.elements.jabatan.value

    let assignToKoor = e.target.elements.assignToKoor.value

    // find list of users by username from assignToKoor
    assignToKoor = listUsers?.ids.filter((userId) => listUsers.entities[userId].username === assignToKoor)

    if (isAdmin || isManager || isKoordinator) {
        return toast.update(toastId, {
            title: 'Failed to add new task',
            description: 'You are not authorized to add new task',
            status: 'error',
            duration: 9000,
            isClosable: true,
        });
    }
  
    try {
      await addNewTaskKoor({
          taskKoorName: taskKoorName.value,
          projectName: project.projectName,
          assignToKoor,
          statusTaskKoor: "Open",
          originatorPIC: {
              id: idUser,
              name,
              email: emailUser,
              roles,
          },
          kategoriReport,
          faktorPenyebab: faktorPenyebab.value,
          permintaan: permintaan.value,
          evaluasi: evaluasi.value,
          rencanaKebutuhanLaporan: rencanaKebutuhanLaporan.value,
          namaPemohon: namaPemohon.value,
          jabatan: jabatan.value,
          email: email.value,
          phone: phone.value,
          attachment,
      });

      toast.update(toastId, {
        title: 'New task koor added',
        description: 'New task koor has been added successfully',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      onClose();
      setLoading(false);

      toast.update(toastId, {
        title: 'Failed to add new task',
        description: error?.data?.message || 'Please try again later',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    } finally {
      onClose();
      setLoading(false);

      if (isShare) {
        const assignKoorPhone = listUsers.entities[assignToKoor[0]].phone
        const nama_koor = listUsers.entities[assignToKoor[0]].name
  
        await sendWhatsappDirect({
          name: name,
          to: formatPhoneNumber(assignKoorPhone),
          template: TemplateWhatsAppID.sendTaskToKoor,
          variables: {
            nama_koor,
            nama_pic_proyek: name,
            nama_report: taskKoorName.value,
            nama_project: project.projectName,
          },
          file: 'unfile',
        })
  
        localStorage.removeItem('isShare');

        toast({
          title: 'Whatsapp sent successfully',
          description: 'Task has been sent to Koordinator',
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top',
        })
      }
    }

    setLoading(false);
    onClose();
  };

  let content;

  if (isLoading) content = <PulseLoader color={"#2BACE3"} />;

//   if (isError && error.data.message !== 'No tasks found') {
//     content = <p className="errmsg">{error?.data?.message}</p>;
//   }

  if (isSuccess) {
    const { ids, entities } = tasksKoor;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = ids.slice(indexOfFirstItem, indexOfLastItem);

    const tableContent =
        currentItems?.length &&
        currentItems.map((taskId, index) => <TaskKoor count={index + 1} key={taskId} taskId={taskId} />);

    const totalPages = Math.ceil(ids.length / itemsPerPage);
    const paginationControls = (
      <Flex justify="flex-end" mt={4}>
        {Array.from({ length: totalPages }, (_, index) => (
          <Button
            key={index}
            onClick={() => setCurrentPage(index + 1)}
            mx={1}
            colorScheme={currentPage === index + 1 ? "blue" : "gray"}
          >
            {index + 1 === totalPages ? "Last" : index + 1}
          </Button>
        ))}
      </Flex>
    );

    content = (
        <BodyTemplate
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            documentTitle="Task List"
            pageTitle="Task Koordinator"
            searchBarPlaceHolder="task"
            searchValue={searchUser}
            setSearchValue={setSearchUser}
            form={<NewTaskKoorForm />}
            modalTitle="Create New Task"
            onSubmit={onSubmit}
            useCreate={!!isManager || !!isPICProject}
            isShareWhatsapp={true}
            isLoadingSubmit={loading}
      >
        <TaskKoorTable>{tableContent}</TaskKoorTable>
        {totalPages > 1 && paginationControls}
      </BodyTemplate>
    );
    } else if (error?.data?.message === 'Tasks Koor not found' ?? !isSuccess) {
        content = (
            <BodyTemplate
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={onClose}
                documentTitle="Task List"
                pageTitle="Task Koordinator"
                searchBarPlaceHolder="task"
                searchValue={searchUser}
                setSearchValue={setSearchUser}
                form={<NewTaskKoorForm />}
                modalTitle="Create New Task"
                onSubmit={onSubmit}
                useCreate={!!isManager || !!isPICProject}
                isShareWhatsapp={true}
            >
                <Text>No tasks found</Text>
            </BodyTemplate>
        );
  }

    return content;
};

export default TasksKoorList;
