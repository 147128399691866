import React, { useState } from 'react'
import CreateModal from '../../../components/BodyTemplate/CreateModal';
import { Text, useToast } from '@chakra-ui/react';
import { useDeleteTaskMutation } from '../tasksApiSlice';

const DeleteTask = ({
  taskName,
  id,
  isDelete,
  setIsDelete
}) => {
  const [deleteTask, {
}] = useDeleteTaskMutation()

  const toast = useToast();
  const [isLoad, setIsLoad] = useState(false);

  const onDelete = async () => {
    setIsLoad(true);

    const toastId = toast({
      title: 'Deleting task',
      description: 'Please wait a moment.',
      status: 'info',
      duration: 9000,
      isClosable: true,
    });

    try {
      await deleteTask({ id });
  
      toast({
        title: 'Success delete task',
        description: `You are was successfully delete ${taskName} task.`,
        status: 'success',
        duration: 9000,
        position: 'top',
        isClosable: true,
      })

      toast.update(toastId, {
        title: 'Success delete task',
        description: `You are was successfully delete ${taskName} task.`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      setIsLoad(false);
      setIsDelete(false);
     
      toast({
        title: 'Failed to delete task',
        description: error?.data?.message || 'Please try again later',
        status: 'error',
        duration: 9000,
        position: 'top',
        isClosable: true,
      });
    } finally {
      setIsLoad(false);
      setIsDelete(false);
    }
  };

  return (
    <CreateModal
      modalTitle={"Delete Project"}
      isOpen={isDelete}
      onClose={() => {
      setIsDelete(false);
      }}
      buttonPrimaryText={"Delete"}
      onSubmit={onDelete}
      isLoading={isLoad}
      isDisabled={isLoad}
  >
      <Text
      fontSize={"18px"}
      >
        Apa kamu yakin ingin menghapus task {taskName}?
      </Text>
    </CreateModal>
  )
}

export default React.memo(DeleteTask)