// React core and custom hooks
import { useAddNewProjectMutation, useGetProjectsQuery } from "./projectsApiSlice";
import Project from "./Project";
import { PulseLoader } from "react-spinners";

// Chakra Components and Icons

// Proprietary Components
import BodyTemplate from "../../components/BodyTemplate/BodyTemplate";
import ProjectTable from "./ProjectTable";
import { useState } from "react";
import { Button, Flex, Text, useDisclosure, useToast } from "@chakra-ui/react";
import NewProjectForm from "./NewProjectForm";
import useAuth from "../../hooks/useAuth";

const ProjectsList = () => {
  const {
    data: projects,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetProjectsQuery("projectsList", {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const toast = useToast();

  const { isAdmin } = useAuth();

  const [addNewProject, {}] = useAddNewProjectMutation();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [searchUser, setSearchUser] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const itemsPerPage = 10;

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const toastId = toast({
      title: "Submitting new project",
      description: "Please wait a moment.",
      status: "info",
      duration: 9000,
      isClosable: true,
    });

    const {
      project,
      location,
      type,
      manager,
      kasi,
      email,
      picName,
      picPhone,
      ksoImage,
    } = e.target;
    let picNameMap = Array.from(picName).map((item) => item.value);
    let picPhoneMap = Array.from(picPhone).map((item) => item.value);
    let pic = [];

    if (picNameMap.length === 0 ) {
      picNameMap = picName.value;
    }

    if (picPhoneMap.length === 0) {
      picPhoneMap = picPhone.value;
    }

    if (typeof picNameMap === 'string' && typeof picPhoneMap === 'string') {
      pic = [{
        name: picNameMap,
        phone: picPhoneMap,
      }];
    } else {
      pic = picNameMap.map((name, index) => ({
        name: name,
        phone: picPhoneMap[index],
      }));
    }

    if (isAdmin) {
      return toast.update(toastId, {
        title: "You are not allowed to add new project",
        description: "You are not allowed to add new project.",
        status: "error",
        duration: 9000,
        position: 'top',
        isClosable: true,
      });
    }

    try {
      await addNewProject({
        project: project.value,
        location: location.value,
        type: type.value,
        manager: manager.value,
        kasi: kasi.value,
        pic,
        email: email.value,
        ksoImage: ksoImage.value,
      });

      toast.update(toastId, {
        title: "Project submitted",
        description: "Project has been submitted successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      onClose();
      setLoading(false);

      toast.update(toastId, {
        title: "Failed to submit new project",
        description: "Failed to submit new project.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      onClose();
      setLoading(false);
    }

    onClose();
    setLoading(false);
  };

  let content;

  if (isLoading) content = <PulseLoader color={"#2BACE3"} />;

  // if (isError && error.data.message !== 'No projects found') {
  //   content = <p className="errmsg">{error?.data?.message}</p>;
  // }

  if (isSuccess) {
    const { ids } = projects;
    let idsFiltered = ids;

    if (searchUser) {
      idsFiltered = ids?.filter((projectId) => {
        const { project, location, type } = projects.entities[projectId];
        const search = searchUser.toLowerCase();
        return (
          project.toLowerCase().includes(search) ||
          location.toLowerCase().includes(search) ||
          type.toLowerCase().includes(search)
        );
      });
    }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = idsFiltered.slice(indexOfFirstItem, indexOfLastItem);

    const tableContent =
      currentItems?.length &&
      currentItems.map((projectId, index) => <Project count={index + 1} key={projectId} projectId={projectId} />);

    const totalPages = Math.ceil(idsFiltered.length / itemsPerPage);
    const paginationControls = (
      <Flex justify="flex-end" mt={4}>
        {Array.from({ length: totalPages }, (_, index) => (
          <Button
            key={index}
            onClick={() => setCurrentPage(index + 1)}
            mx={1}
            colorScheme={currentPage === index + 1 ? "blue" : "gray"}
          >
            {index + 1 === totalPages ? "Last" : index + 1}
          </Button>
        ))}
      </Flex>
    );

    content = (
      <BodyTemplate
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        documentTitle="Project List"
        pageTitle="Project"
        searchBarPlaceHolder="project"
        searchValue={searchUser}
        setSearchValue={setSearchUser}
        form={<NewProjectForm />}
        modalTitle="Create Project"
        onSubmit={onSubmit}
        isLoadingSubmit={loading}
      >
        <ProjectTable>{tableContent}</ProjectTable>
        {totalPages > 1 && paginationControls}
      </BodyTemplate>
    );
  } else if (error?.data?.message === 'No projects found' ?? !isSuccess) {
    content = (
      <BodyTemplate
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        documentTitle="Project List"
        pageTitle="Project"
        searchBarPlaceHolder="project"
        searchValue={searchUser}
        setSearchValue={setSearchUser}
        form={<NewProjectForm />}
        modalTitle="Create Project"
        onSubmit={onSubmit}
      >
        <Text>No projects found</Text>
      </BodyTemplate>
    );
  }

  return content;
};

export default ProjectsList;
