import React, { Component, useState } from 'react'
import CreateModal from '../../../components/BodyTemplate/CreateModal';
import { FormControl, FormLabel, Input, Stack, Text, useToast } from '@chakra-ui/react';
import { useUpdateTaskDownloadMutation } from '../../tasks/tasksApiSlice';
import { useLoginMutation } from '../../auth/authApiSlice';
import useAuth from '../../../hooks/useAuth';
import { getPDFTasks } from '../../../utils/Functions';

const UpdateDownload = ({
  tasks,
  isUpdate,
  setIsUpdate
}) => {
  const [updateTaskDownload, {
    isLoading,
  }] = useUpdateTaskDownloadMutation();

  const [login, { isLoading: isLoadingVerify }] = useLoginMutation()
  const toast = useToast();
  // const [show, setShow] = useState({
  //   pw: false,
  //   cpw: false,
  // });

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoadingPDF, setIsLoadingPDF] = useState(false);

  // const [payload, setPayload] = useState({
  //   id: tasks.id,
  //   name: "",
  //   email: "",
  // });

  const onUpdate = async (e) => {
    setIsLoadingPDF(true)

    const toastId = toast({
      title: 'Silahkan tunggu...',
      description: 'Sedang melakukan Verifikasi untuk mendownlaod.',
      status: 'info',
      duration: null,
      isClosable: true,
    });

    // await updateTaskDownload({
    //   ...payload,
    // });
    e.preventDefault();
    try {
      await login({ username, password }).unwrap()
        .then(async (res) => {
          const { email, name } = res;
          const payload = {
            id: tasks._id,
            email,
            name,
          }

          if (res?.accessToken) {
            toast.update(toastId, {
              title: 'Berhasil Indentifikasi Akun',
              description: 'Report Sedang di download.',
              status: 'success',
              duration: 5000,
              isClosable: true,
            });
            try {
              await updateTaskDownload({
                ...payload,
              }).then(async (res) => {
                if (res) {
                  try {
                    await getPDFTasks(tasks)
    
                    toast({
                      title: "Download Report",
                      description: "Download Report Berhasil",
                      status: "success",
                      position: 'top',
                      duration: 9000,
                      isClosable: true,
                    });
                  } catch (error) {
                    setIsLoadingPDF(false);
                    setIsUpdate(false);
                    toast.update(toastId, {
                      title: 'Download Failed',
                      description: 'Ada masalah ketika download report.',
                      status: 'error',
                      duration: 5000,
                      isClosable: true,
                    });
                  } finally {
                    setIsLoadingPDF(false);
                    setIsUpdate(false);
                  }
                }
              }).catch((err) => {
                  console.log("Error: ", err)
                }
              );
            } catch (error) {
              toast.update(toastId, {
                title: 'Download Failed',
                description: 'Ada masalah ketika download report.',
                status: 'error',
                duration: 5000,
                isClosable: true,
              });
            }
          }

        })
        .catch((err) => {
          console.log("Error: ", err)
        }
      );

      setIsUpdate(false);
      setIsLoadingPDF(false);
    } catch {
      console.log("Verify Failed")
      setIsUpdate(false);
      setIsLoadingPDF(false);
    }
  }

  return (
    <CreateModal
    modalTitle={"Download Report Ini"}
    isOpen={isUpdate}
    onClose={() => {
      setIsUpdate(false);
    }}
    buttonPrimaryText={"Download"}
    onSubmit={onUpdate}
    isDisabled={isLoading || !username || !password || isLoadingPDF}
    isLoading={isLoadingPDF}
    >
      <Stack>
        <Text
          fontSize="md"
        >Silahkan masukkan Akun anda untuk dapat mendownload Report Ini</Text>
        {/* Input */}
        <FormControl mt={4}>
          <FormLabel>E-mail/Username</FormLabel>
          <Input
            id="email"
            placeholder="badrun@wika.id"
            autoFocus
            type="name"
            autoComplete="off"
            required
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
          />
        </FormControl>
        <FormControl mt={2}>
          <FormLabel>Password</FormLabel>
          <Input
            id="password"
            autoFocus
            type="password"
            autoComplete="off"
            required
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </FormControl>
      </Stack>
    </CreateModal>
  )
}

export default React.memo(UpdateDownload);