export const StandardsUsed = [
  "Pengelasan",
  "Pengecatan",
  "Pengukuran"
]

export const KategoriReport = ['Manajemen Engineering', 'Perencanaan Engineering (Drawing, Metode Konstruksi, dan Analisis)', 'Manajemen & Mitigasi Risiko Engineering', 'Value Engineering', 'BIM Implementations'];

export const Jabatan = ['Manager Proyek', 'Kasi Engineering', 'Kasi Komersial', 'Kepala Seksi', 'Komersial', 'Engineering', 'Staff'];

export const TemplateWhatsAppID = {
  sendReport: 'db5cc78e-c342-4b0b-a8fb-0336b05ba1cd',
  sendTaskToEngineer: '888323ba-c868-4e9b-a7d2-7e32d226baef',
  sendTaskToKoor: '4179b81d-49e2-4e67-ae66-f4498c92a043',
  sendReviewToKoorMeng: '5a36e84a-63cc-4641-9135-23e22a5cc68a',
  sendApproval: '8f3abbae-9cda-4d65-a540-157e9d297eaa',
  sendRevision: 'aa3ed605-715b-450d-a1d8-62527f737dcb',
}

export const notificationsDummy = [
  {
      status: "info",
      title: "Koor Eka",
      message: "You have a new task",
      date: "2021-09-01",
  },
  {
      status: "warning",
      title: "Koor Eka",
      message: "You have a new task",
      date: "2021-09-01",
  },
  {
      status: "success",
      title: "Koor Eka",
      message: "You have a new task",
      date: "2021-09-01",
  },
  {
      status: "error",
      title: "Koor Eka",
      message: "You have a new task",
      date: "2021-09-01",
  },
];

export const statusIcon = (status) => {
    switch (status) {
        case "info":
            return "blue.500";
        case "warning":
            return "orange.500";
        case "success":
            return "green.500";
        case "error":
            return "red.500";
        default:
            return "blue.500";
    }
};
