import { useParams } from 'react-router-dom'
import { useGetUsersQuery } from '../users/usersApiSlice'
import useAuth from '../../hooks/useAuth'
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../hooks/useTitle'
import { Box, Stack, Text, VStack } from '@chakra-ui/react'
import { pathFile } from '../../utils/Functions'
import './common/tags.styles.css'
import { useGetTasksKoorQuery } from './tasksKoorApiSlice'

const DetailTaskKoor = () => {
    useTitle('Wika Engineering Report System - Detail Task Koordinator')

    const { id } = useParams()

    const { isManager, isAdmin, isKoordinator, isPICProject } = useAuth()

    const { task } = useGetTasksKoorQuery("tasksKoorList", {
        selectFromResult: ({ data }) => ({
        task: data?.entities[id],
        }),
    });

    const { users } = useGetUsersQuery("usersList", {
        selectFromResult: ({ data }) => ({
            users: data?.ids.map(id => data?.entities[id])
        }),
    })

    if (!task || !users?.length) return <PulseLoader color={"#FFF"} />

    if (isManager || isKoordinator || isAdmin || isPICProject) {
        const filterAttachment = task?.attachment.filter((file) => file !== "")
        return (
            <>
                <Text
                    fontSize="x-large"
                    fontWeight="bold"
                    color="gray.800"
                    mb={4}
                >Task Koordinator</Text>
                <VStack
                    align="start"
                    w="100%"
                >
                <Box
                    p={6}
                    mt={4}
                    mb={4}
                    borderRadius="md"
                    boxShadow="md"
                    bg="white"
                    w="100%"
                >
                <Stack flexDirection={"row"} pl={8} pr={8} mt={4} mb={4}>
                    <Text fontSize="xl" fontWeight="bold">Report Name :</Text>
                    <Text>{task?.taskKoorName}</Text>
                </Stack>
                <Stack flexDirection={"row"} pl={8} pr={8} mt={4} mb={4}>
                    <Text fontSize="xl" fontWeight="bold">Project Name :</Text>
                    <Text>{task?.projectName}</Text>
                </Stack>
                <Stack flexDirection={"row"} pl={8} pr={8} mt={4} mb={4}>
                    <Text fontSize="xl" fontWeight="bold">Kategori Report :</Text>
                    <Text>{task?.kategoriReport}</Text>
                </Stack>
                {task?.faktorPenyebab && (
                    <Stack flexDirection={"row"} pl={8} pr={8} mt={4} mb={4}>
                    <Text fontSize="xl" fontWeight="bold">Faktor Penyebab :</Text>
                    <Text>{task?.faktorPenyebab}</Text>
                    </Stack>
                )}
                {task?.permintaan && (
                    <Stack flexDirection={"row"}  pl={8} pr={8} mt={4} mb={4}>
                    <Text fontSize="xl" fontWeight="bold">Permintaan :</Text>
                    <Text>{task?.permintaan}</Text>
                    </Stack>
                )}
                {task?.evaluasi && (
                    <Stack flexDirection={"row"}  pl={8} pr={8} mt={4} mb={4}>
                    <Text fontSize="xl" fontWeight="bold">Evaluasi :</Text>
                    <Text >{task?.evaluasi}</Text>
                    </Stack>
                )}
                {task?.rencanaKebutuhanLaporan && (
                    <Stack flexDirection={"row"}  pl={8} pr={8} mt={4} mb={4}>
                    <Text fontSize="xl" fontWeight="bold">Rencana Kebutuhan Laporan :</Text>
                    <Text >{task?.rencanaKebutuhanLaporan}</Text>
                    </Stack>
                )}
                {task?.attachment.length > 0 && (
                    <Stack flexDirection={"row"}  pl={8} pr={8} mt={4} mb={4}>
                    <Text fontSize="xl" fontWeight="bold">Lampiran :</Text>
                    {filterAttachment.map((file, index) => (
                            <Text
                                fontSize={"medium"}
                                color="blue.500"
                                onClick={() => {
                                window.open(pathFile(file), "_blank")
                                }}
                                key={index}
                                cursor="pointer"
                            >
                                {index === 0 ? `${index + 1}.` : " "} {file && `${file}${index === filterAttachment.length - 1 ? "" : ", "}`}
                            </Text>
                            ))}
                    </Stack>
                )}
                {task?.namaPemohon && (
                    <Stack flexDirection={"row"}  pl={8} pr={8} mt={4} mb={4}>
                    <Text fontSize="xl" fontWeight="bold">Nama Pemohon :</Text>
                    <Text>{task?.namaPemohon}</Text>
                    </Stack>
                )}
                {task?.jabatan && (
                    <Stack flexDirection={"row"}  pl={8} pr={8} mt={4} mb={4}>
                    <Text fontSize="xl" fontWeight="bold">Jabatan :</Text>
                    <Text>{task?.jabatan}</Text>
                    </Stack>
                )}
                {task?.email && (
                    <Stack flexDirection={"row"}  pl={8} pr={8} mt={4} mb={4}>
                    <Text fontSize="xl" fontWeight="bold">Email :</Text>
                    <Text>{task?.email}</Text>
                    </Stack>
                )}
                {task?.phone && (
                    <Stack flexDirection={"row"}  pl={8} pr={8} mt={4} mb={4}>
                    <Text fontSize="xl" fontWeight="bold">No. HP :</Text>
                    <Text>{task?.phone}</Text>
                    </Stack>
                )}
                </Box>
            </VStack>
            </>
        )
    }

    return <></>;
}
export default DetailTaskKoor