import { memo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetTasksKoorQuery } from "./tasksKoorApiSlice";

import { Tr, Td, Button, Image, Tooltip, Flex, HStack } from "@chakra-ui/react";
import { MdEdit, MdDelete } from "react-icons/md";
import StandardTdComponent from "../../components/StandardTdComponent";
import useAuth from "../../hooks/useAuth";
import { ROLES } from "../../config/roles";
import { statusColor } from "../../utils/Functions";
import { DeleteTask, UpdateTask } from "./common";
import { FaEye } from "react-icons/fa";

const TaskKoor = ({ count, taskId }) => {
    const [isDelete, setIsDelete] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [isEnter, setIsEnter] = useState(false);
    
    const { status: userStatus } = useAuth();
    
    const { task } = useGetTasksKoorQuery("tasksKoorList", {
        selectFromResult: ({ data }) => ({
        task: data?.entities[taskId],
        }),
    });
    
    const navigate = useNavigate();

    const handleView = () => {
        navigate(`/koor-assignment/${taskId}`);
    };
    
    if (task) {
        const {
            taskKoorName,
            projectName,
            statusTaskKoor,
        } = task;
    
    
        return (
            <>
                <Tr
                onMouseEnter={() => {
                    setIsEnter(true);
                }}
                onMouseLeave={() => {
                    setIsEnter(false);
                }}
                backgroundColor={isEnter && "rgb(240,240,240)"}
                >
                <StandardTdComponent>{count}</StandardTdComponent>
                <StandardTdComponent textAlign="left">
                    <Tooltip
                        label={taskKoorName}
                    >
                        {taskKoorName}
                    </Tooltip>
                </StandardTdComponent>
                <StandardTdComponent textAlign="left">
                    <Tooltip
                        label={projectName}
                    >
                    {projectName}
                    </Tooltip>
                </StandardTdComponent>
                <StandardTdComponent>
                    <Button
                    size={"xs"}
                    bg={statusColor(statusTaskKoor)}
                    color={"white"}
                    _hover={{ bg: statusColor(statusTaskKoor) }}
                    >
                    {statusTaskKoor}
                    </Button>
                </StandardTdComponent>
                <Td
                    style={
                    userStatus === ROLES.Engineering
                        ? {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        } : {}
                    }
                >
                    <Button
                        leftIcon={<FaEye />}
                        bg={"teal.500"}
                        textColor={"white"}
                        size={"sm"}
                        onClick={handleView}
                        _hover={{ bg: "teal.600" }}
                        marginRight={2}
                    >
                        View
                    </Button>
                    <Button
                        leftIcon={<MdEdit />}
                        colorScheme="twitter"
                        size={"sm"}
                        marginRight={2}
                        onClick={() => {
                            if (["Completed"].includes(statusTaskKoor)) return;
                            setIsUpdate(true);
                        }}
                        disabled={["Completed", "Open"].includes(statusTaskKoor) && ["Engineering", "Manager", "PIC Project", "Admin"].includes(userStatus)}
                        opacity={(["Completed", "Open"].includes(statusTaskKoor) && ["Engineering", "Admin", "PIC Project"].includes(userStatus)) || (["Completed"].includes(statusTaskKoor) && ["Koordinator"].includes(userStatus)) ? 0.5 : 1}
                        _disabled={{ cursor: "not-allowed" }}
                    >
                    Update
                    </Button>
                    {userStatus !== "Koordinator" && (
                    <Button
                        leftIcon={<MdDelete />}
                        colorScheme="red"
                        size={"sm"}
                        onClick={() => {
                            if (["Completed"].includes(statusTaskKoor)) return;
                            setIsDelete(true);
                        }}
                        disabled={["Completed"].includes(statusTaskKoor)}
                        opacity={["Completed"].includes(statusTaskKoor) ? 0.5 : 1}
                        _disabled={{ cursor: "not-allowed" }}
                    >
                        Delete
                    </Button>
                )}
                </Td>
                </Tr>
                <DeleteTask
                    taskName={taskKoorName}
                    id={taskId}
                    isDelete={isDelete}
                    setIsDelete={setIsDelete}
                />
                <UpdateTask
                    task={task}
                    isUpdate={isUpdate}
                    setIsUpdate={setIsUpdate}
                />
            </>
        );
    }
    
    return null;
};

export default memo(TaskKoor);