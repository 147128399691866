// React core and custom hooks
import useTitle from "../hooks/useTitle"
import { useNavigate } from "react-router-dom"

// Chakra Components and Icons
import { Center, Text, Box, Button } from "@chakra-ui/react"

const NotFound = () => {
    useTitle('Not Found!')
    const navigate = useNavigate()
  

    return (
        <Center height="100svh" p={'10rem'}>
            <Box>
                <Text fontSize='15rem' as='u'>404</Text>
                <Text fontSize='6xl'>The page you are looking for may have been moved, deleted, or possibly never existed.</Text>
                <Button onClick={()=>{
                    navigate(-1, { replace: true })
                }}>Back</Button>
            </Box>
        </Center>
    )
}

export default NotFound