import React, { useState } from 'react'
import CreateModal from '../../../components/BodyTemplate/CreateModal';
import { Button, FormControl, FormLabel, HStack, Input, Select, Text, useToast } from '@chakra-ui/react';
import { useUpdateTaskKoorMutation } from '../tasksKoorApiSlice';
import { useAddNewTaskMutation, useGetTasksQuery } from '../../tasks/tasksApiSlice';
import { formatPhoneNumber, sendWhatsappDirect } from '../../../utils/Functions';
import { useGetUsersQuery } from '../../users/usersApiSlice';
import { ROLES } from '../../../config/roles';
import useAuth from '../../../hooks/useAuth';
import { useGetProjectsQuery } from '../../projects/projectsApiSlice';
import { TemplateWhatsAppID } from '../../../utils/Constant';

const UpdateTask = ({
  task,
  isUpdate,
  setIsUpdate
}) => {
  const {
    username: user,
    username,
    phone,
    image,
    status,
    name,
  } = useAuth();

  const [updateTaskKoor, {
    }] = useUpdateTaskKoorMutation()

  const [assignTo, setAssignTo] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const [addNewTask, {}] = useAddNewTaskMutation();

  const {
    data: tasksExisting,
  } = useGetTasksQuery("tasksList", {
    pollingInterval: 15000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const { data: listUsers } = useGetUsersQuery("usersList", {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const { data: listProjects } = useGetProjectsQuery("projectsList", {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const toast = useToast();
  const findProjectByName = listProjects?.ids.find((projectId) => listProjects.entities[projectId].project === task.projectName);
  const findProjectById = listProjects?.entities[findProjectByName];

  const verifyListUsers = listUsers?.ids
  .filter((userId) => listUsers.entities[userId].roles[0] === ROLES.Engineering)
  .map((userId) => {
    const { username } = listUsers.entities[userId];

    return {
      id: userId,
      label: username.charAt(0).toUpperCase() + username.slice(1),
      value: username,
    };
  });

  const listUsersOptions = verifyListUsers?.filter(
    (data) => data.value !== user
  );

  const onUpdate = async () => {
    setIsLoad(true);

    const toastId = toast({
      title: 'Updating task',
      description: 'Please wait a moment.',
      status: 'info',
      duration: 9000,
      isClosable: true,
      position: 'top',
    });

    const refactoredAssignTo = assignTo.map((assign) => ({
      id: assign.id,
      name: assign.value,
      roles: assign.roles,
    }))

    const assignReports = refactoredAssignTo.map((role) => role.name).join(" & ");

    try {
      await addNewTask({
        taskName: task.taskKoorName,
        projectName: findProjectById.project,
        projectType: findProjectById.type,
        projectLocation: findProjectById.location,
        assignTo: [...refactoredAssignTo.map((assign) => assign.id)],
        assignRoles: refactoredAssignTo,
        originator: [
          {
            username,
            phone,
            image,
            status: "Open",
          },
        ],
        report: {
          date: new Date().toISOString(),
          description: `Task Assign To Engineers - ${assignReports}`,
          status: "Open",
          updatedBy: {
            role: status,
            name: name,
          },
        }
      });

      toast.update(toastId, {
        title: 'Success update task',
        description: `You are was successfully update ${task.taskKoorName} task.`,
        status: 'success',
        duration: 9000,
        isClosable: true,
        position: 'top',
      });
    } catch (error) {
      setIsLoad(false);
      setIsUpdate(false);

      toast({
        title: 'Failed to update task',
        description: error?.data?.message || 'Please try again later',
        status: 'error',
        duration: 9000,
        position: 'top',
        isClosable: true,
      });
    } finally {
      setIsUpdate(false);
      setIsLoad(false);

      const assignToId = [...refactoredAssignTo.map((assign) => assign.id)]
      const assignUsersData = assignToId.map((userId) => ({
        name: listUsers.entities[userId].name,
        phone: listUsers.entities[userId].phone,
      }));
  
      if (Array.isArray(assignUsersData) && assignUsersData && assignUsersData.length > 0) {
        for (const assignUsers of assignUsersData) {
          await sendWhatsappDirect({
            name,
            to: formatPhoneNumber(assignUsers.phone),
            template: TemplateWhatsAppID.sendTaskToEngineer,
            variables: {
              nama_engineer: assignUsers.name,
              nama_koor: name,
              nama_report: task.taskKoorName,
              nama_project: task.projectName,
            },
            file: "unfile",
          });
        }

        toast({
          title: 'Whatsapp sent successfully',
          description: 'Task has been sent to Engineers',
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top',
        });
      }
  
      await updateTaskKoor({ id: task._id, statusTaskKoor: "Completed" });
    }
  };

  return (
    <CreateModal
      modalTitle={"Form Create Sign Task To Koor"}
      isOpen={isUpdate}
      onClose={() => {
      setIsUpdate(false);
      }}
      buttonPrimaryText={"Confirm"}
      onSubmit={onUpdate}
      isLoading={isLoad}
      isDisabled={isLoad}
  >
    <>
      <FormControl id="taskName" isRequired mt="14px">
        <FormLabel>Report Name</FormLabel>
        <Input
          mt="12px"
          name="taskName"
          type="text"
          placeholder="Report Name"
          value={task.taskKoorName}
          disabled
        />
      </FormControl>
      <FormControl id="projectName" isRequired mt="14px">
        <FormLabel>Project Name</FormLabel>
        <Select
          id={findProjectByName}
          mt="12px"
          name="projectName"
          variant="outline"
          placeholder="Select Project"
          value={findProjectById?.project}
          disabled
        >
          <option key={findProjectById?._id} value={findProjectById?.project}>
            {findProjectById?.project}
          </option>
        </Select>
      </FormControl>
      <FormControl id="assignTo" isRequired mt="14px">
        <FormLabel>Assign To</FormLabel>
        {assignTo.map((assign, index) => (
          <HStack
            key={index}
            alignItems={"center"}
            justifyContent={"space-between"}
            mt="12px"
          >
            <Select
              name="assignTo"
              variant="outline"
              placeholder="Select User"
              value={assign.value}
              onChange={(e) => {
                const selectedUser = listUsersOptions.find(user => user.value === e.target.value);
                const newAssignTo = [...assignTo];
                newAssignTo[index] = {
                  ...newAssignTo[index],
                  id: selectedUser.id,
                  value: selectedUser.value,
                };
                setAssignTo(newAssignTo);
                setIsError(false);
              }}
            >
              {listUsersOptions?.map((user) => (
                <option key={user.id} value={user.value}>
                  {user.label}
                </option>
              ))}
            </Select>
            <Input
              name="assignRoles"
              id={assign.id}
              alt={assign.value}
              type="text"
              placeholder="Job Desc"
              value={assign.roles}
              onChange={(e) => {
                const newAssignTo = [...assignTo];
                newAssignTo[index].roles = e.target.value;
                setAssignTo(newAssignTo);
                setIsError(false);
              }}
            />
            <Button
              colorScheme="whatsapp"
              size={"sm"}
              onClick={() => {
                if (assignTo.length > 2) {
                  setIsError(true);
                  return;
                }
                setAssignTo([...assignTo, { id: '', label: '', value: '', roles: '' }]);
              }}
              disabled={isError}
            >
              +
            </Button>
          </HStack>
        ))}
        {assignTo.length === 0 && (
          <HStack
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Text
              fontSize={"14px"}
              textOverflow={"ellipsis"}
              overflow={"hidden"}
              color={"gray.500"}
            >Tambahkan Engineers</Text>
            <Button
              colorScheme="whatsapp"
              size={"sm"}
              onClick={() => {
                setAssignTo([{ id: '', label: '', value: '', roles: '' }]);
              }}
            >
              +
            </Button>
          </HStack>
        )}
        {isError && (
          <Text
            fontSize={"14px"}
            textOverflow={"ellipsis"}
            overflow={"hidden"}
            color={"red.500"}
          >
            Max Engineers is 3
          </Text>
        )}
      </FormControl>
    </>
    </CreateModal>
  )
}

export default React.memo(UpdateTask)