import React, { useEffect } from 'react'
import CreateModal from '../../../components/BodyTemplate/CreateModal';
import { Text, useToast } from '@chakra-ui/react';
import { useDeleteTaskKoorMutation } from '../tasksKoorApiSlice';

const DeleteTask = ({
  taskName,
  id,
  isDelete,
  setIsDelete
}) => {
  const [deleteTask, {
    isError,
    isLoading,
    isSuccess,
}] = useDeleteTaskKoorMutation()

  const toast = useToast();

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: "Task berhasil dihapus",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }

    if (isError) {
      toast({
        title: "Gagal menghapus task",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }
  , [isSuccess, isError, toast]);

  const onDelete = async () => {
    await deleteTask(id);

    setIsDelete(false);
  };

  return (
    <CreateModal
      modalTitle={"Delete Project"}
      isOpen={isDelete}
      onClose={() => {
      setIsDelete(false);
      }}
      buttonPrimaryText={"Delete"}
      onSubmit={onDelete}
      isLoading={isLoading}
  >
      <Text
      fontSize={"18px"}
      >
        Apa kamu yakin ingin menghapus task koor {taskName}?
      </Text>
    </CreateModal>
  )
}

export default React.memo(DeleteTask)