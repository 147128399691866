// React core and custom hooks
import { useState } from "react";

// Chakra Components and Icons
import {
  FormControl,
  FormLabel,
  Input,
  InputRightElement,
  InputGroup,
  Select,
  Icon,
  Text,
  HStack,
  Button,
} from "@chakra-ui/react";

import { AiOutlineFileAdd } from "react-icons/ai";
import { useGetProjectsQuery } from "../projects/projectsApiSlice";
import { useGetUsersQuery } from "../users/usersApiSlice";
import { useGetTasksQuery } from "./tasksApiSlice";
import useAuth from "../../hooks/useAuth";
import { ROLES } from "../../config/roles";

// // Proprietary Components
// import { ROLES } from "../../config/roles";

const NewTaskForm = () => {
  const { username: user } = useAuth();

  const { data: listProjects } = useGetProjectsQuery("projectsList", {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const { data: listUsers } = useGetUsersQuery("usersList", {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const { data: listTasks } = useGetTasksQuery("tasksList", {
    pollingInterval: 15000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const listProjectsOptions = listProjects?.ids.map((projectId) => {
    const { project, location, type } = listProjects.entities[projectId];
    return {
      id: projectId,
      label: location,
      value: project,
      type,
    };
  });
  
  const verifyListUsers = listUsers?.ids
  .filter((userId) => listUsers.entities[userId].roles[0] === ROLES.Engineering)
  .map((userId) => {
    const { username } = listUsers.entities[userId];

    return {
      id: userId,
      label: username.charAt(0).toUpperCase() + username.slice(1),
      value: username,
    };
  });

  const listUsersOptions = verifyListUsers?.filter(
    (data) => data.value !== user
  );

  const [payload, setPayload] = useState({
    taskName: "",
    projectName: "",
    assignTo: [],
    assignRoles: [],
  });

  const [assignTo, setAssignTo] = useState([]);
  const [isError, setIsError] = useState(false);
  const [projectSelected, setProjectSelected] = useState({});

  return (
    <>
      <FormControl id="taskName" isRequired mt="14px">
        <FormLabel>Report Name</FormLabel>
        <Input
          mt="12px"
          name="taskName"
          type="text"
          placeholder="Report Name"
          value={payload.taskName}
          onChange={(e) =>
            setPayload((prev) => ({
              ...prev,
              taskName: e.target.value,
            }))
          }
        />
      </FormControl>
      <FormControl id="projectName" isRequired mt="14px">
        <FormLabel>Project Name</FormLabel>
        <Select
          id={projectSelected.type}
          data-alt={projectSelected.label}
          mt="12px"
          name="projectName"
          variant="outline"
          placeholder="Select Project"
          value={projectSelected.value}
          onChange={(e) =>{
            const selectedProject = listProjectsOptions.find(project => project.value === e.target.value);
            setProjectSelected(selectedProject);
          }}
        >
          {listProjectsOptions?.map((project) => (
            <option key={project.id} value={project.value}>
              {project.value}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl id="assignTo" isRequired mt="14px">
        <FormLabel>Assign To</FormLabel>
        {assignTo.map((assign, index) => (
          <HStack
            key={index}
            alignItems={"center"}
            justifyContent={"space-between"}
            mt="12px"
          >
            <Select
              name="assignTo"
              variant="outline"
              placeholder="Select User"
              value={assign.value}
              onChange={(e) => {
                const selectedUser = listUsersOptions.find(user => user.value === e.target.value);
                const newAssignTo = [...assignTo];
                newAssignTo[index] = {
                  ...newAssignTo[index],
                  id: selectedUser.id,
                  value: selectedUser.value,
                };
                setAssignTo(newAssignTo);
                setIsError(false);
              }}
            >
              {listUsersOptions?.map((user) => (
                <option key={user.id} value={user.value}>
                  {user.label}
                </option>
              ))}
            </Select>
            <Input
              name="assignRoles"
              id={assign.id}
              alt={assign.value}
              type="text"
              placeholder="Job Desc"
              value={assign.roles}
              onChange={(e) => {
                const newAssignTo = [...assignTo];
                newAssignTo[index].roles = e.target.value;
                setAssignTo(newAssignTo);
                setIsError(false);
              }}
            />
            <Button
              colorScheme="whatsapp"
              size={"sm"}
              onClick={() => {
                if (assignTo.length > 2) {
                  setIsError(true);
                  return;
                }
                setAssignTo([...assignTo, { id: '', label: '', value: '', roles: '' }]);
              }}
              disabled={isError}
            >
              +
            </Button>
          </HStack>
        ))}
        {assignTo.length === 0 && (
          <HStack
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Text
              fontSize={"14px"}
              textOverflow={"ellipsis"}
              overflow={"hidden"}
              color={"gray.500"}
            >Tambahkan Engineers</Text>
            <Button
              colorScheme="whatsapp"
              size={"sm"}
              onClick={() => {
                setAssignTo([{ id: '', label: '', value: '', roles: '' }]);
              }}
            >
              +
            </Button>
          </HStack>
        )}
        {isError && (
          <Text
            fontSize={"14px"}
            textOverflow={"ellipsis"}
            overflow={"hidden"}
            color={"red.500"}
          >
            Max Engineers is 3
          </Text>
        )}
      </FormControl>
    </>
  );
};

export default NewTaskForm;
