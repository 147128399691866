
import { Box, Text, Input, Icon, Flex, TagLabel, InputGroup, InputLeftElement, InputRightElement  } from "@chakra-ui/react"
import { MdOutlineSearch } from "react-icons/md"
import { FaFilter } from "react-icons/fa";

const SearchBar = ({ placeholder = "", value = "", onChange = () => {} }) => {
    return (
        <InputGroup border={"1px"} borderColor={"#4763E4"} rounded={"10px"} mb={'14px'}>
            <InputLeftElement >
                <Icon as={MdOutlineSearch} color={"rgb(75,75,75)"}/>
            </InputLeftElement >
            <Input
                type='tel'
                placeholder={`Search ${placeholder}`}
                value={value}
                onChange={onChange}
            />
            <InputRightElement>
                <Icon as={FaFilter} color={"rgb(75,75,75)"}/>
            </InputRightElement>
        </InputGroup>
    )
}

export default SearchBar