import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice"
import { listTasks } from "./TaskSlice";

const tasksAdapter = createEntityAdapter({
    sortComparer: (a, b) => (a.completed === b.completed) ? 0 : a.completed ? 1 : -1
})

const initialState = tasksAdapter.getInitialState()

export const tasksApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getTasks: builder.query({
            query: () => ({
                url: '/tasks',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
                const loadedTasks = responseData.map(task => {
                    task.id = task._id
                    return task
                });
                return tasksAdapter.setAll(initialState, loadedTasks)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Task', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Task', id }))
                    ]
                } else return [{ type: 'Task', id: 'LIST' }]
            },
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    const mappedData = data.ids.map((taskId) => data.entities[taskId])
                    dispatch(listTasks(mappedData))
                } catch (err) {
                    console.log(err)
                }
            }
        }),
        getTasksById: builder.query({
            query: (id) => ({
                url: `/tasks/${id}`,
                method: 'GET',
                headers: {
                  'Authorization': `Bearer ${process.env.REACT_APP_API_KEY_PASS}`
                }
              }),
              providesTags: (result, error, id) => [{ type: 'Task', id }]
        }),
        updateTaskDownload: builder.mutation({
            query: (tasks) => ({
                url: `/tasks/${tasks.id}/download`,
                method: 'POST',
                headers: {
                  'Authorization': `Bearer ${process.env.REACT_APP_API_KEY_PASS}`,
                },
                body: {
                    ...tasks,
                }
            }),
            invalidatesTags: [
                { type: 'Task', id: "LIST" }
            ]
        }),
        updateTaskSendEmail: builder.mutation({
            query: (tasks) => ({
                url: `/tasks/${tasks.id}/send-email`,
                method: 'POST',
                headers: {
                  'Authorization': `Bearer ${process.env.REACT_APP_API_KEY_PASS}`,
                },
                body: {
                    ...tasks,
                }
            }),
            invalidatesTags: [
                { type: 'Task', id: "LIST" }
            ]
        }),
        addNewTask: builder.mutation({
            query: initialTask => ({
                url: '/tasks',
                method: 'POST',
                body: {
                    ...initialTask,
                }
            }),
            invalidatesTags: [
                { type: 'Task', id: "LIST" }
            ]
        }),
        updateTask: builder.mutation({
            query: initialTask => ({
                url: '/tasks',
                method: 'PATCH',
                body: {
                    ...initialTask,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Task', id: arg.id }
            ]
        }),
        deleteTask: builder.mutation({
            query: ({ id }) => ({
                url: `/tasks`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Task', id: arg.id }
            ]
        }),
    }),
})

export const {
    useGetTasksQuery,
    useGetTasksByIdQuery,
    useUpdateTaskDownloadMutation,
    useUpdateTaskSendEmailMutation,
    useAddNewTaskMutation,
    useUpdateTaskMutation,
    useDeleteTaskMutation,
} = tasksApiSlice

// returns the query result object
export const selectTasksResult = tasksApiSlice.endpoints.getTasks.select()

// creates memoized selector
const selectTasksData = createSelector(
    selectTasksResult,
    tasksResult => tasksResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllTasks,
    selectById: selectTaskById,
    selectIds: selectTaskIds
    // Pass in a selector that returns the tasks slice of state
} = tasksAdapter.getSelectors(state => selectTasksData(state) ?? initialState)