// React core and custom hooks
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { useSendLogoutMutation } from "../features/auth/authApiSlice";

// Chakra Components and Icons
import {
  Box,
  Flex,
  useDisclosure,
  useToast,
  Spacer,
  Text,
  List,
  Stack,
  ListItem,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { FaBell, FaUser } from "react-icons/fa";
import { statusIcon } from "../utils/Constant";
import { useDeleteNotificationsAllMutation, useDeleteNotificationsByIdMutation, useGetNotificationsByIdQuery } from "../utils/helper/notifications/notificationsApiSlice";

const DashHeader = () => {
  const { id } = useAuth();

  const {
    data: notifications,
    isLoading,
    isSuccess: isNotificationsSuccess,
    isError: isNotificationsError,
    error: notificationsError,
  } = useGetNotificationsByIdQuery(id, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const [deleteNotificationsAll, {}] = useDeleteNotificationsAllMutation();
  const [deleteNotificationsById, {}] = useDeleteNotificationsByIdMutation();

  const navigate = useNavigate();
  const path = useLocation().pathname;
  const [sendLogout, { isSuccess, isError, error }] =
    useSendLogoutMutation();
  const toast = useToast();
  const { isOpen, onClose } = useDisclosure();
  const [showNotifications, setShowNotifications] = useState(false);

  const toggleNotifications = () => {
      setShowNotifications(!showNotifications);
  };

  useEffect(() => {
    if (isSuccess) navigate("/");
    if (isError)
      toast({
        title: "An error occurred.",
        description: error?.data?.message,
        status: "error",
        position: 'top',
        duration: 9000,
        isClosable: true,
      });
  }, [isSuccess, isError, navigate, toast, error]);

  const handleLogout = async () => {
    await sendLogout()

    onClose()
    navigate("/")
  }

  const handleNotificationClearAll = async () => {
    await deleteNotificationsAll({ id });

    toast({
        title: "Success",
        description: "Semua notifications berhasil di hapus.",
        status: "success",
        position: 'top',
        duration: 9000,
        isClosable: true,
    });
  }

  const handleNotificationClearOne = async (notificationId) => {
    await deleteNotificationsById({ id: notificationId });

    toast({
        title: "Success",
        description: "Notifications berhasil di hapus.",
        status: "success",
        position: 'top',
        duration: 9000,
        isClosable: true,
    });
  }

  if (path === "/dash") return null;

  return (
    <Flex
      as="header"
      align="center"
      justify="space-between"
      wrap="wrap"
      color="white"
      h={"60px"}
      paddingX={"36px"}
    >
      <Spacer />
      <Box
        display={{ base: isOpen ? "block" : "none", md: "block" }}
        flexBasis={{ base: "100%", md: "auto" }}
      >
        <Flex
          align={"center"}
          justify={"center"}
        >
          <Box position="relative" mr={4}>
            <FaBell
                size={24}
                color="black"
                onClick={toggleNotifications}
                style={{ cursor: 'pointer' }}
            />
            {notifications && notifications.length > 0 ? (
                <Box
                    position="absolute"
                    top="-5px"
                    right="-5px"
                    bg="red"
                    color="white"
                    borderRadius="50%"
                    width="16px"
                    height="16px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    fontSize="12px"
                >
                    {notifications.length}
                </Box>
            ) : null}
          </Box>
          {showNotifications && (
              <Box
                  position="absolute"
                  top="2.8em"
                  right="6em"
                  bg="white"
                  boxShadow="md"
                  borderRadius="md"
                  p={4}
                  zIndex={1}
                  maxHeight="450px"
                  overflowY="auto"
              >
                  <List spacing={3} width={580}>
                      <Stack
                          flexDirection={"row"}
                          borderBottom="1px solid #A1A1AA"
                          pb={2}
                          justifyContent={"space-between"}
                      >
                          <Text fontSize={"md"} as="b" color="black">Notification</Text>
                          <Text fontSize={"md"} color={'blue.500'} onClick={handleNotificationClearAll} cursor={"pointer"}>Clear All</Text>
                      </Stack>
                      {notifications.length > 0 ? notifications.map((notification, index) => (
                          <ListItem key={index}>
                              <Alert status={notification.status} variant="ghost">
                                  <Stack
                                      flexDirection={"row"}
                                      alignItems={"center"}
                                  >
                                      <AlertIcon
                                          color={statusIcon(notification.status)}
                                          boxSize={6}
                                      />
                                      <Flex
                                          flexDirection={"row"}
                                          justifyContent={"space-between"}
                                          width={"100%"}
                                      >
                                        <Stack
                                            spacing={0}
                                            flexDir={"column"}
                                            mr={2}
                                        >
                                            <Text color="black" fontSize={"md"} as="b">{notification.title}</Text>
                                            <Text color="black" fontSize={"sm"}>{notification.message}</Text>
                                            <Text
                                                color="gray.500"
                                                fontSize={"xs"}
                                                mt={1}
                                            >
                                                {notification.date}
                                            </Text>
                                        </Stack>
                                        <Stack
                                            flexDirection={"row"}
                                        >
                                            <Text
                                                fontSize={"md"}
                                                color={"blue.500"}
                                                onClick={() => handleNotificationClearOne(notification._id)}
                                                cursor={"pointer"}
                                            >
                                                Clear
                                            </Text>
                                        </Stack>
                                      </Flex>
                                  </Stack>
                              </Alert>
                          </ListItem>
                      )) : (
                          <ListItem>
                              <Text color="black" fontSize={"md"}>Tidak ada Notification</Text>
                          </ListItem>
                      )}
                  </List>
              </Box>
          )}
          <FaUser
              size={24}
              color="black"
              onClick={handleLogout}
              style={{ cursor: 'pointer' }}
          />
          <Text color={"black"} fontSize={"lg"} as="b" onClick={handleLogout} style={{ cursor: 'pointer' }} ml={2}>Logout</Text>
        </Flex>
      </Box>
    </Flex>
  );
};

export default DashHeader;
