import React, { useState } from 'react'
import CreateModal from '../../../components/BodyTemplate/CreateModal';
import { Button, FormControl, FormLabel, HStack, Input, Text, useToast } from '@chakra-ui/react';
import { useUpdateProjectMutation } from '../projectsApiSlice';
import useAuth from '../../../hooks/useAuth';
import { uploadImage } from '../../../utils/Functions';

const UpdateProject = ({ data, isUpdate, setIsUpdate }) => {
  const [updateProject, {}] = useUpdateProjectMutation();

  const { isAdmin } = useAuth();
  const toast = useToast();

  const {
    id,
    project,
    location,
    type,
    manager,
    kasi,
    pic: dataPIC,
    email,
    ksoImage,
  } = data;

  const [payload, setPayload] = useState({
    project,
    location,
    type,
    manager,
    kasi,
    pic: dataPIC,
    email,
    validEmail: false,
  });
  const [pic, setPic] = useState(dataPIC);
  const [isError, setIsError] = useState(false);

  const onUpdate = async () => {
    if (isAdmin) {
      return toast({
        title: "Not allowed.",
        description: "You are is admin not capable to update project.",
        status: "error",
        duration: 9000,
        isClosable: true,
      })
    }

    await updateProject({ id, ...payload });

    toast({
      title: 'Success update project',
      description: `You are was successfully update ${payload.project} project.`,
      status: 'success',
      duration: 9000,
      position: 'top',
      isClosable: true,
    });

    setIsUpdate(false);
  };

  return (
    <CreateModal
      modalTitle={"Update Project"}
      isOpen={isUpdate}
      onClose={() => {
      setIsUpdate(false);
      }}
      buttonPrimaryText={"Update"}
      onSubmit={onUpdate}
  >
    <FormControl id="project" isRequired>
        <FormLabel>Project</FormLabel>
        <Input
            type="text"
            value={payload.project}
            onChange={(e) => setPayload({ ...payload, project: e.target.value })}
        />
        </FormControl>
        <FormControl id="location" isRequired>
        <FormLabel>Location</FormLabel>
        <Input
            type="text"
            value={payload.location}
            onChange={(e) => setPayload({ ...payload, location: e.target.value })}
        />
        </FormControl>
        <FormControl id="type" isRequired>
        <FormLabel>Type</FormLabel>
        <Input
            type="text"
            value={payload.type}
            onChange={(e) => setPayload({ ...payload, type: e.target.value })}
        />
        </FormControl>
        <FormControl id="manager" isRequired>
        <FormLabel>Manager</FormLabel>
        <Input
            type="text"
            value={payload.manager}
            onChange={(e) => setPayload({ ...payload, manager: e.target.value })}
        />
        </FormControl>
        <FormControl id="pic" isRequired mt="14px">
          <FormLabel>Contact PIC Project</FormLabel>
          {pic.map((person, index) => (
            <HStack
              key={index}
              alignItems={"center"}
              justifyContent={"space-between"}
              mt="12px"
            >
              <Input
                name="picName"
                type="text"
                placeholder="PIC Name"
                value={person.name}
                onChange={(e) => {
                  const newPIC = [...pic];
                  newPIC[index].name = e.target.value;
                  setPic(newPIC);
                  setIsError(false);
                }}
              />
              <Input
                name="picPhone"
                type="number"
                placeholder="PIC Phone/WA"
                value={person.phone}
                onChange={(e) => {
                  const newPIC = [...pic];
                  newPIC[index].phone = e.target.value;
                  setPic(newPIC);
                  setIsError(false);
                }}
              />
              <Button
                colorScheme="whatsapp"
                size={"sm"}
                onClick={() => {
                  if (pic.length > 2) {
                    setIsError(true);
                    return;
                  }
                  setPic([...pic, { name: "", phone: "" }]);
                }}
                disabled={isError}
              >
                +
              </Button>
            </HStack>
          ))}
          {pic.length === 0 && (
            <HStack
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Text
                fontSize={"14px"}
                textOverflow={"ellipsis"}
                overflow={"hidden"}
                color={"gray.500"}
              >Tambahkan PIC</Text>
              <Button
                colorScheme="whatsapp"
                size={"sm"}
                onClick={() => {
                  setPic([{ name: "", phone: "" }]);
                }}
              >
                +
              </Button>
            </HStack>
          )}
          {isError && (
            <Text
              fontSize={"14px"}
              textOverflow={"ellipsis"}
              overflow={"hidden"}
              color={"red.500"}
            >
              Max PIC is 3
            </Text>
          )}
        </FormControl>
        <FormControl id="email" isRequired mt={4}>
        <FormLabel>Email</FormLabel>
        <Input
            type="email"
            value={payload.email}
            onChange={(e) => setPayload({ ...payload, email: e.target.value })}
        />
        </FormControl>
        <FormControl id="ksoImage" mt={4}>
          <FormLabel>Logo Laporan</FormLabel>
          <Input
            id="ksoImage"
            name="ksoImage"
            accept="image/*"
            autoFocus
            type="file"
            curosr={"pointer"}
            onChange={(e) => {
              const images = uploadImage(e.target?.files[0] ?? null)
                .then((image) => {
                  setPayload((prev) => {
                    return {
                      ...prev,
                      ksoImage: image,
                    };
                  });
                }).catch((err) => console.err(err))

              return images
            }}
          />
        </FormControl>
    </CreateModal>
  )
}

export default React.memo(UpdateProject)