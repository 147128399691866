import { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setCredentials } from './authSlice'
import { useLoginMutation } from './authApiSlice'
import usePersist from '../../hooks/usePersist'
import useTitle from '../../hooks/useTitle'
import PulseLoader from 'react-spinners/PulseLoader'
import {
    Button,
    Checkbox,
    FormControl,
    FormLabel,
    Input,
    Text,
    Grid,
    Image,
    Container,
    Flex,
    useToast,
} from '@chakra-ui/react'

const imgLogo = require('../../img/logo_wika_text.png');
const imgBg = require('../../img/img-bg2.png');

const Login = () => {
    useTitle('Engineering Login')

    const userRef = useRef()
    const errRef = useRef()
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [errMsg, setErrMsg] = useState('')
    const [persist, setPersist] = usePersist()
    const [isLoading, setIsLoading] = useState(false)

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const toast = useToast()

    const [login, { isLoading: isLoadingLogin }] = useLoginMutation()

    useEffect(() => {
        userRef.current.focus()
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [username, password])


    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        const toastId = toast({
            title: 'Logging in',
            description: 'Please wait a moment.',
            status: 'info',
            duration: 9000,
            isClosable: true,
        })

        try {
            const { accessToken, refreshToken } = await login({ username, password }).unwrap()
            dispatch(setCredentials({ accessToken, refreshToken }))
            setUsername('')
            setPassword('')
            navigate('/dash')

            toast.update(toastId, {
                title: 'Login Success',
                description: `Selamat Datang ${username} di WIKA E Report System`,
                status: 'success',
                duration: 5000,
                isClosable: true,
            })
        } catch (err) {
            setIsLoading(false)

            if (!err.status) {
                setErrMsg('No Server Response');
            } else if (err.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.status === 401) {
                setErrMsg('Akun tidak ditemukan atau password salah');
            } else {
                setErrMsg(err.data?.message);
            }
            errRef.current.focus();

            toast.update(toastId, {
                title: 'Login Failed',
                description: errMsg,
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        } finally {
            setIsLoading(false)
        }
    }

    const handleUserInput = (e) => setUsername(e.target.value)
    const handlePwdInput = (e) => setPassword(e.target.value)
    const handleToggle = () => setPersist(prev => !prev)

    const errClass = errMsg ? "errmsg" : "offscreen"

    if (isLoadingLogin) return <PulseLoader color={"#2BACE3"} />

    const content = (
        <Container
            minHeight="100vh"
            minWidth="100vw"
            display="flex"
            alignItems="center"
            justifyContent="center"
            bgImage={imgBg}
            bgPosition="center"
            bgRepeat="no-repeat"
            bgSize="cover"
        >
            <Grid
                bg="white"
                pt={8}
                pb={8}
                pl={16}
                pr={16}
                borderRadius="md"
                boxShadow="lg"
            >
                <Flex alignSelf="center">
                    <Image
                        src={imgLogo}
                        alt="Logo"
                        mb={8}
                        height="84px"
                        width="119px"
                        mx="auto"
                    />
                </Flex>

                <Text ref={errRef} className={errClass} aria-live="assertive">{errMsg}</Text>

                <form className="form" onSubmit={handleSubmit}>
                    <Text fontSize="3xl" fontWeight="bold">
                        Login
                    </Text>
                    <FormControl id="username">
                        <FormLabel>Email / Username</FormLabel>
                        <Input
                            type="text"
                            ref={userRef}
                            value={username}
                            onChange={handleUserInput}
                            autoComplete="off"
                            required
                            focusBorderColor="blue.400"
                            placeholder="akhlak@wika.id"
                        />
                    </FormControl>

                    <FormControl id="password">
                        <FormLabel>Password</FormLabel>
                        <Input
                            type="password"
                            onChange={handlePwdInput}
                            value={password}
                            required
                            focusBorderColor="blue.400"
                            placeholder="password"
                        />
                    </FormControl>

                    <FormControl display="flex" alignItems="center" id="persist">
                        <Checkbox
                            onChange={handleToggle}
                            isChecked={persist}
                            colorScheme="blue"
                        />
                        <FormLabel ml={2}>Remember me</FormLabel>
                    </FormControl>

                    <Button type="submit" bgColor="blue.500" textColor="white" variant="solid" isDisabled={isLoading} isLoading={isLoading}>
                        Sign In
                    </Button>
                </form>
            </Grid>
        </Container>
    )

    return content
}
export default Login