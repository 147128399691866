// React core and custom hooks
import useTitle from "../../hooks/useTitle";

// Chakra Components and Icons
import { Box, Text, Button, Flex } from "@chakra-ui/react";
import { HiPlus } from "react-icons/hi";

// Proprietary Components
import SearchBar from "../SearchBar/SearchBar";
import CreateModal from "./CreateModal";
import { useNavigate } from "react-router-dom";

const BodyTemplate = ({
  documentTitle = "",
  pageTitle = "",
  page="",
  searchBarPlaceHolder = "",
  children,
  useCreate = true,
  modalTitle = "",
  searchValue = "",
  setSearchValue = () => {},
  isOpen = false,
  onOpen = () => {},
  onClose = () => {},
  form = () => {},
  onSubmit = () => {},
  isShareWhatsapp = false,
  isHistoryDownload = false,
  isLoadingSubmit = false,
}) => {
  useTitle(documentTitle);

  const navigate = useNavigate();

  return (
    <>
      {!isHistoryDownload && (
        <Text marginBottom={"20px"} fontSize={"26px"} fontWeight={"bold"}>
          {pageTitle}
        </Text>
      )}
      <Box
        rounded={"16px"}
        background={"#FFFFFF"}
        paddingX={"44px"}
        paddingY={"20px"}
        boxShadow={"0px 2px 2px rgba(0, 0, 0, 0.25)"}
      >
        <Flex mb={"12px"} align={"center"}>
          <Text fontSize={"18px"} flex={"auto"}>
            {pageTitle} List
          </Text>
          {useCreate && (
            <Button
              onClick={() => {
                if (page === "tasks") {
                  navigate(`/${page}/new`);
                } else {
                  onOpen();
                }
              }}
              leftIcon={<HiPlus />}
              colorScheme="whatsapp"
            >
              Create
            </Button>
          )}
        </Flex>
        <SearchBar
          placeholder={searchBarPlaceHolder}
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
        />
        {children}
      </Box>
      <CreateModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        modalTitle={modalTitle}
        onSubmit={onSubmit}
        isShareWhatsapp={isShareWhatsapp}
        isDisabled={isLoadingSubmit}
        isLoading={isLoadingSubmit}
      >
        {form}
      </CreateModal>
    </>
  );
};

export default BodyTemplate;
