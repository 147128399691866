import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice"
import { listTasks } from "./TaskKoorSlice";

const tasksAdapter = createEntityAdapter({
    sortComparer: (a, b) => (a.completed === b.completed) ? 0 : a.completed ? 1 : -1
})

const initialState = tasksAdapter.getInitialState()

export const tasksKoorApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getTasksKoor: builder.query({
            query: () => ({
                url: '/tasks-koor',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
                const loadedTasks = responseData.map(task => {
                    task.id = task._id
                    return task
                });
                return tasksAdapter.setAll(initialState, loadedTasks)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'TaskKoor', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'TaskKoor', id }))
                    ]
                } else return [{ type: 'TaskKoor', id: 'LIST' }]
            },
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    const mappedData = data.ids.map((taskId) => data.entities[taskId])
                    dispatch(listTasks(mappedData))
                } catch (err) {
                    console.log(err)
                }
            }
        }),
        createTaskKoor: builder.mutation({
            query: (tasks) => ({
                url: '/tasks-koor',
                method: 'POST',
                body: tasks
            }),
            invalidatesTags: [{ type: 'TaskKoor', id: 'LIST' }]
        }),
        updateTaskKoor: builder.mutation({
            query: initialTask => ({
                url: '/tasks-koor',
                method: 'PATCH',
                body: {
                    ...initialTask,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'TaskKoor', id: arg.id }
            ]
        }),
        deleteTaskKoor: builder.mutation({
            query: (id) => ({
                url: `/tasks-koor/`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'TaskKoor', id: arg.id }
            ]
        })
    })
})


export const {
    useGetTasksKoorQuery,
    useCreateTaskKoorMutation,
    useUpdateTaskKoorMutation,
    useDeleteTaskKoorMutation
} = tasksKoorApiSlice

export const selectTasksKoorResult = tasksKoorApiSlice.endpoints.getTasksKoor.select()

const selectTasksKoorData = createSelector(
    selectTasksKoorResult,
    taskKoorResult => taskKoorResult.data
)

export const {
    selectAll: selectAllTasksKoor,
    selectById: selectTaskKoorById,
    selectIds: selectTaskKoorIds
} = tasksAdapter.getSelectors(state => selectTasksKoorData(state) ?? initialState)
