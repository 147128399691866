// React core and custom hooks
import { memo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetTasksQuery } from "./tasksApiSlice";

// Chakra Components and Icons
import { Tr, Td, Button, Image, Tooltip, Flex, HStack } from "@chakra-ui/react";
import { MdEdit, MdDelete } from "react-icons/md";
import StandardTdComponent from "../../components/StandardTdComponent";
import { converTime, getPDFTasks, pathFile, statusColor } from "../../utils/Functions";
import { DeleteTask } from "./common";
import useAuth from "../../hooks/useAuth";
import { ROLES } from "../../config/roles";
import { FaEye } from "react-icons/fa";

const DefaultProfilePicture = require("../../img/default-profile-picture.jpg");

const Task = ({ count, taskId }) => {
  const [isDelete, setIsDelete] = useState(false);
  const [isEnter, setIsEnter] = useState(false);

  const { status: userStatus } = useAuth();

  const { task } = useGetTasksQuery("tasksList", {
    selectFromResult: ({ data }) => ({
      task: data?.entities[taskId],
    }),
  });

  const navigate = useNavigate();

  if (task) {
    const {
      taskName,
      projectName,
      statusTask,
      deadline,
      originator,
    } = task;

    const renderOriginator = (origin, i) => {
      const statusVerify = origin.status === "Open" ? "" : origin.status;

      if (origin) {
        return (
          <HStack
            key={i}
            ml={i === 0 ? "4" : 0}
            mr={i === 0 ? "2" : 0}
          >
            <Tooltip key={origin.username} label={`${origin.username} ${statusVerify}`} aria-label="username">
              <Image
                borderRadius="full"
                border={`4px solid ${statusColor(origin.status)}`}
                boxSize="30px"
                src={pathFile(origin.image) || DefaultProfilePicture}
                alt={origin.username}
              />
            </Tooltip>
          </HStack>
        );
      }
    };

    const handleUpdate = () => {
      if (["Completed", "Waiting Review"].includes(statusTask) && ["Engineering", "Admin"].includes(userStatus)) return;

      navigate(`/tasks/${taskId}`);
      // if (userStatus === ROLES.Engineering) {
      // } else {
      //   // 
      // }
    };

    // const modalUpdateTask = () => (
    //   <Modal isOpen={isUpdate} onClose={onClose}>
    //     <ModalOverlay />
    //     <ModalContent>
    //       <ModalHeader>Update Task</ModalHeader>
    //       <ModalCloseButton />
    //       <ModalBody>
    //         {/* Update for non engineers */}
    //       </ModalBody>
    //     </ModalContent>
    //   </Modal>
    // );

    const handleView = () => {
      navigate(`/reports/${taskId}`);
      // if (userStatus === ROLES.Engineering) {
      // } else {
      //   // 
      // }
      
      // getPDFTasks(task ?? {});
    };

    // const modalUpdateTask = () => (
    //   <Modal isOpen={isUpdate} onClose={onClose}>
    //     <ModalOverlay />
    //     <ModalContent>
    //       <ModalHeader>Update Task</ModalHeader>
    //       <ModalCloseButton />
    //       <ModalBody>
    //         {/* Update for non engineers */}
    //       </ModalBody>
    //     </ModalContent>
    //   </Modal>
    // );

    return (
      <>
        <Tr
          onMouseEnter={() => {
            setIsEnter(true);
          }}
          onMouseLeave={() => {
            setIsEnter(false);
          }}
          backgroundColor={isEnter && "rgb(240,240,240)"}
        >
          <StandardTdComponent>{count}</StandardTdComponent>
          <StandardTdComponent textAlign="left">
            <Tooltip label={taskName} aria-label="taskName">
              {taskName}
            </Tooltip>
          </StandardTdComponent>
          <StandardTdComponent textAlign="left">
            <Tooltip label={projectName} aria-label="projectName">
              {projectName}
            </Tooltip>
          </StandardTdComponent>
          <StandardTdComponent>
            <Tooltip label={converTime(deadline)} aria-label="deadline">
              {converTime(deadline)}
            </Tooltip>
          </StandardTdComponent>
          <StandardTdComponent>
            <Flex justifyContent="center" flexDir="row" align="center">
              {originator && originator.map((origin, i) => renderOriginator(origin, i))}
            </Flex>
          </StandardTdComponent>
          <StandardTdComponent>
            <Button
              size={"xs"}
              bg={statusColor(statusTask)}
              color={"white"}
              _hover={{ bg: statusColor(statusTask) }}
            >
              {statusTask}
            </Button>
          </StandardTdComponent>
          <Td
            style={
              userStatus === ROLES.Engineering
                ? {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                } : {}
            }
          >
            {/* {userStatus !== "Engineering" && ( */}
              <Button
                leftIcon={<FaEye />}
                bg={"teal.500"}
                textColor={"white"}
                size={"sm"}
                onClick={handleView}
                _hover={{ bg: "teal.600" }}
                marginRight={2}
              >
                View
              </Button>
            {/* )} */}
            <Button
              leftIcon={<MdEdit />}
              colorScheme="twitter"
              size={"sm"}
              marginRight={2}
              onClick={handleUpdate}
              disabled={["Completed", "Waiting Review"].includes(statusTask) && ["Engineering", "Admin"].includes(userStatus)}
              opacity={["Completed", "Waiting Review"].includes(statusTask) && ["Engineering", "Admin"].includes(userStatus) ? 0.5 : 1}
              _disabled={{ cursor: "not-allowed" }}
            >
              Update
            </Button>
            {userStatus !== "Engineering" && (
              <Button
                leftIcon={<MdDelete />}
                colorScheme="red"
                size={"sm"}
                onClick={() => {
                    if (["Completed", "Waiting Review"].includes(statusTask)) return;
                    setIsDelete(true);
                }}
                disabled={["Completed", "Waiting Review"].includes(statusTask)}
                opacity={["Completed", "Waiting Review"].includes(statusTask) ? 0.5 : 1}
                _disabled={{ cursor: "not-allowed" }}
              >
                Delete
              </Button>
            )}
          </Td>
        </Tr>
        <DeleteTask
          taskName={taskName}
          id={taskId}
          isDelete={isDelete}
          setIsDelete={setIsDelete}
        />
      </>
    );
  } else return null;
};

const memoizedTask = memo(Task);

export default memoizedTask;
