import { createSlice } from "@reduxjs/toolkit";

const taskKoorSlice = createSlice({
    name: "tasksKoor",
    initialState: {
        list: []
    },
    reducers: {
        listTasksKoor: (state, action) => {
            state.list = action.payload;
        }
    }
});

export const { listTasks } = taskKoorSlice.actions;

export default taskKoorSlice.reducer;

export const listDataTasksKoor = (state) => state.tasksKoor.list;