import Quill from 'quill';

const Image = Quill.import('formats/image');

class CustomImage extends Image {
  static create(value) {
    const node = super.create(value);
    // node.setAttribute('style', 'max-height: 400px;');
    return node;
  }
}

Quill.register(CustomImage, true);