import { createSlice } from '@reduxjs/toolkit'

const authSlice = createSlice({
    name: 'auth',
    initialState: { token: null },
    reducers: {
        setCredentials: (state, action) => {
            const { accessToken } = action.payload
            state.token = accessToken
            localStorage.setItem('jwt', accessToken)
            if (action.payload.refreshToken) {
                localStorage.setItem('jwt_reverse', action.payload.refreshToken)
            }
            localStorage.setItem('jwt_exp', Date.now() + 7 * 24 * 60 * 60 * 1000)
        },
        logOut: (state, action) => {
            state.token = null
            localStorage.removeItem('jwt')
            localStorage.removeItem('jwt_exp')
            localStorage.removeItem('jwt_reverse')
        },
    }
})

export const { setCredentials, logOut } = authSlice.actions

export default authSlice.reducer

export const selectCurrentToken = (state) => state.auth.token