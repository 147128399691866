import { useState } from "react";
// React core and custom hooks
import { useAddNewUserMutation, useGetUsersQuery } from "./usersApiSlice";
import User from "./User";
import PulseLoader from "react-spinners/PulseLoader";

// Chakra Components and Icons

// Proprietary Components
import BodyTemplate from "../../components/BodyTemplate/BodyTemplate";
import UserTable from "./UserTable";
import NewUserForm from "./NewUserForm";
import { Button, Flex, useDisclosure } from "@chakra-ui/react";

const UsersList = () => {
  const {
    data: users,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetUsersQuery("usersList", {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const [addNewUser, {}] = useAddNewUserMutation()

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [searchUser, setSearchUser] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  let content;

  if (isLoading) content = <PulseLoader color={"#2BACE3"} />;

  if (isError) {
    content = <p className="errmsg">{error?.data?.message}</p>;
  }

  if (isSuccess) {
    const { ids } = users;
    let idsFiltered = ids;

    if (searchUser) {
      idsFiltered = ids.filter((userId) => {
        const { username, email, phone } = users.entities[userId];
        const search = searchUser.toLowerCase();
        return (
          username.toLowerCase().includes(search) ||
          email.toLowerCase().includes(search) ||
          phone.toLowerCase().includes(search)
        );
      });
    }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = idsFiltered.slice(indexOfFirstItem, indexOfLastItem);

    const tableContent =
      currentItems?.length && currentItems.map((userId, index) => <User count={index + 1} key={userId} userId={userId} />);

    const onSubmit = async (e) => {
      e.preventDefault();
      const localImageProfile = localStorage.getItem(`profile_image_${e.target.username.value}`);
      const image = localImageProfile || e.target.image.value;
      
      await addNewUser({
        image,
        username: e.target.username.value,
        password: e.target.password.value,
        phone: e.target.phone.value,
        email: e.target.email.value,
        name: e.target.name.value,
        roles: e.target.role.value,
      });

      localStorage.removeItem(`profile_image_${e.target.username.value}`);
      onClose();
    };

    const totalPages = Math.ceil(idsFiltered.length / itemsPerPage);
    const paginationControls = (
      <Flex justify="flex-end" mt={4}>
        {Array.from({ length: totalPages }, (_, index) => (
          <Button
            key={index}
            onClick={() => setCurrentPage(index + 1)}
            mx={1}
            colorScheme={currentPage === index + 1 ? "blue" : "gray"}
          >
            {index + 1 === totalPages ? "Last" : index + 1}
          </Button>
        ))}
      </Flex>
    );

    content = (
      <BodyTemplate
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        documentTitle="Users List"
        pageTitle="User"
        searchBarPlaceHolder="user"
        searchValue={searchUser}
        setSearchValue={setSearchUser}
        form={<NewUserForm onSubmit />}
        modalTitle="Add new user!"
        onSubmit={onSubmit}
      >
        <UserTable>{tableContent}</UserTable>
        {totalPages > 1 && paginationControls}
      </BodyTemplate>
    );
  }

  return content;
};

export default UsersList;
