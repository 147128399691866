import { createSlice } from "@reduxjs/toolkit";

const taskSlice = createSlice({
    name: "tasks",
    initialState: {
        list: []
    },
    reducers: {
        listTasks: (state, action) => {
            state.list = action.payload;
        }
    }
});

export const { listTasks } = taskSlice.actions;

export default taskSlice.reducer;

export const listDataTasks = (state) => state.tasks.list;