// Chakra Components and Icons
import {
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Stack,
  Checkbox,
} from "@chakra-ui/react";
import { useState } from "react";

const CreateModal = ({
  isOpen = false,
  onOpen = () => {},
  onClose = () => {},
  children,
  modalTitle = "",
  onSubmit = () => {},
  buttonPrimaryText = "Create",
  buttonSecondaryText = "Close",
  isDisabled = false,
  isShareWhatsapp = false,
  isConfirm = false,
  onDraftConfirm = () => {},
  isLoading = false,
}) => {
  const [isShare, setIsShare] = useState(false);

  return (
    <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxWidth="60%">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit(e);
          }}
        >
          <ModalHeader>{modalTitle}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{children}</ModalBody>

          <ModalFooter
            display="flex"
            justifyContent="space-between"
          >
            <Stack direction="row">
              <Button
                colorScheme="whatsapp"
                type="submit"
                disabled={isDisabled}
                _disabled={{
                  bg: "gray.300",
                  cursor: "not-allowed",
                }}
                isLoading={isLoading}
              >
                {isLoading ? `${buttonPrimaryText}...` : buttonPrimaryText}
              </Button>
              <Button
                variant={isConfirm ? "" : "ghost"}
                colorScheme={isConfirm ? "blue" : ""}
                borderColor="gray.300"
                borderWidth={1}
                ml={3}
                onClick={isConfirm ? onDraftConfirm : onClose}
                isDisabled={isLoading}
              >
                {isLoading ? `${buttonSecondaryText}...` : buttonSecondaryText}
              </Button>
            </Stack>
            {isShareWhatsapp && (
              <Stack>
              <Checkbox
                size="md"
                colorScheme="whatsapp"
                defaultChecked={false}
                onChange={(e) => {
                  setIsShare(e.target.checked);
                  localStorage.setItem("isShare", e.target.checked);
                }}
              >
                Share to whatsapp
              </Checkbox>
            </Stack>
            )}
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default CreateModal;
