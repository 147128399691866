import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../../app/api/apiSlice";

const notificationsAdapter = createEntityAdapter({})

const initialState = notificationsAdapter.getInitialState()

export const notificationsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getNotificationsById: builder.query({
            query: (id) => ({
                url: `/notifications/${id}`,
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${process.env.REACT_APP_API_KEY_PASS}`
                }
            }),
            providesTags: (result, error, id) => [{ type: 'Notification', id }]
        }),
        deleteNotificationsById: builder.mutation({
            query: ({ id }) => ({
                url: `/notifications/${id}`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Notification', id: arg.id }
            ]
        }),
        deleteNotificationsAll: builder.mutation({
            query: ({ id }) => ({
                url: `/notifications/user/${id}`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Notification', id: arg.id }
            ]
        }),
    }),
})

export const {
    useGetNotificationsByIdQuery,
    useDeleteNotificationsByIdMutation,
    useDeleteNotificationsAllMutation
} = notificationsApiSlice

export const selectNotificationsResult = notificationsApiSlice.endpoints.getNotificationsById.select()

const selectNotificationsData = createSelector(
    selectNotificationsResult,
    notificationsResult => notificationsResult.data
)

export const {
    selectAll: selectAllNotifications,
    selectById: selectNotificationsById,
} = notificationsAdapter.getSelectors(state => selectNotificationsData(state) ?? initialState)