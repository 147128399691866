import Report from "./Report";
import BodyTemplate from "../../components/BodyTemplate/BodyTemplate";
import ReportTable from "./ReportTable";
import { useGetTasksQuery } from "../tasks/tasksApiSlice";
import { useState } from "react";
import PulseLoader from "react-spinners/PulseLoader";
import { Button, Flex } from "@chakra-ui/react";

const ProjectsList = () => {
  const {
    data: tasks,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetTasksQuery("tasksList", {
    pollingInterval: 15000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const [searchUser, setSearchUser] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  let content;

  if (isLoading) content = <PulseLoader color={"#2BACE3"} />;

  if (isError && error.data.message !== 'No tasks found') {
    content = <p className="errmsg">{error?.data?.message}</p>;
  }

  if (isSuccess) {
    const { ids } = tasks;

    let filteredIds = [...ids];

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredIds.slice(indexOfFirstItem, indexOfLastItem);

    const tableContent =
    currentItems?.length &&
    currentItems.map((taskId, index) => {
      return (
        <Report
          key={index}
          no={index + 1}
          reportId={taskId}
        />
      )
    });

    const totalPages = Math.ceil(filteredIds.length / itemsPerPage);
    const paginationControls = (
      <Flex justify="flex-end" mt={4}>
        {Array.from({ length: totalPages }, (_, index) => (
          <Button
            key={index}
            onClick={() => setCurrentPage(index + 1)}
            mx={1}
            colorScheme={currentPage === index + 1 ? "blue" : "gray"}
          >
            {index + 1 === totalPages ? "Last" : index + 1}
          </Button>
        ))}
      </Flex>
    );

    content = (
      <BodyTemplate
        documentTitle="Report List"
        pageTitle="Report"
        searchBarPlaceHolder="report"
        useCreate={false}
        searchValue={searchUser}
        setSearchValue={setSearchUser}
      >
        <ReportTable>{tableContent}</ReportTable>
        {totalPages > 1 && paginationControls}
      </BodyTemplate>
    );
  }

  return content;
};

export default ProjectsList;
