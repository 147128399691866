// React core and custom hooks
import { memo, useState } from "react";

// Chakra Components and Icons
import { Tr, Td, Icon, Center, Tooltip } from "@chakra-ui/react";
import { MdAssignment } from "react-icons/md";
import StandardTdComponent from "../../components/StandardTdComponent";
import { useNavigate } from "react-router-dom";
import { useGetTasksQuery } from "../tasks/tasksApiSlice";
import { converTime } from "../../utils/Functions";

const Report = ({
  no = 0,
  reportId,
}) => {
  const [isEnter, setIsEnter] = useState(false);

  const { task } = useGetTasksQuery("tasksList", {
    selectFromResult: ({ data }) => ({
      task: data?.entities[reportId],
    }),
  });

  const navigate = useNavigate();

  const handleAction = () => {
    navigate(`/reports/${reportId}`);
  }

  if (task) {
    const {
      taskName,
      projectName,
      projectType,
      statusTask,
      deadline,
    } = task;

    return (
      <Tr
        onMouseEnter={() => {
          setIsEnter(true);
        }}
        onMouseLeave={() => {
          setIsEnter(false);
        }}
        backgroundColor={isEnter && "rgb(240,240,240)"}
      >
        <StandardTdComponent>{no}.</StandardTdComponent>
        <StandardTdComponent textAlign={"left"}>
          <Tooltip
            label={taskName}
          >
            {taskName}
          </Tooltip>
        </StandardTdComponent>
        <StandardTdComponent textAlign={"left"}>
          <Tooltip
              label={projectName}
          >
            {projectName}
          </Tooltip>
        </StandardTdComponent>
        <StandardTdComponent>{projectType}</StandardTdComponent>
        <StandardTdComponent>
          <Tooltip
            label={converTime(deadline)}
          >
            {converTime(deadline)}
          </Tooltip>
        </StandardTdComponent>
        <StandardTdComponent textAlign={"left"}>
          {statusTask}
        </StandardTdComponent>
        <Td>
          <Center cursor={"pointer"} onClick={handleAction}>
            <Icon as={MdAssignment} />
          </Center>
        </Td>
      </Tr>
    );
  } else return null;
};

const memoizedReport = memo(Report);

export default memoizedReport;
