// React core and custom hooks
import { useNavigate, useLocation, Link } from "react-router-dom";

// Chakra Components and Icons
import { Icon, Text, Flex } from "@chakra-ui/react";

const DashSidebarLink = ({ route = "", title = "", path = "", icon = {} }) => {
  return (
    <Link to={route}>
      <Flex
        align={"center"}
        gap={"10px"}
        paddingY={"12px"}
        paddingX={"16px"}
        backgroundColor={path.includes(route) && "#FFFFFF"}
        transitionDuration={"500ms"}
        rounded={"8px"}
        boxShadow={path.includes(route) && "0px 0px 5px #0000001A"}
      >
        <Icon
          as={icon}
          color={path.includes(route) ? "#FFFFFF" : "#2BACE3"}
          bg={path.includes(route) ? "#2BACE3" : "#FFFFFF"}
          borderRadius="md"
          p="6px"
          boxSize={"32px"}
          boxShadow={path.includes(route) ? "none" : "0px 0px 5px #0000001A"}
        />
        <Text
          color={path.includes(route) ? "#2D3748" : "#A0AEC0"}
          fontSize={"16px"}
          as={path.includes(route) ? 'b' : 'p'}
          textShadow={path.includes(route) ? "none" : "0px 0px 5px #0000001A"}
        >
          {title}
        </Text>
      </Flex>
    </Link>
  );
};

export default DashSidebarLink;
