import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { store } from './app/store'
import { Provider } from 'react-redux'
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import { ChakraProvider } from '@chakra-ui/react';
import { Quill } from 'react-quill';
import ImageResize from '@taoqf/quill-image-resize-module';
import './utils/quillImageResize';

if (process.env.NODE_ENV === 'production') disableReactDevTools()
if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

const root = ReactDOM.createRoot(document.getElementById('root'));
Quill.register('modules/imageResize', ImageResize);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
            <Route path="/*" element={
            <ChakraProvider>
              <App />
            </ChakraProvider>
          } />
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
