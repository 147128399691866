import React from 'react'
import CreateModal from '../../../components/BodyTemplate/CreateModal';
import { Text } from '@chakra-ui/react';
import { useDeleteProjectMutation } from '../projectsApiSlice';

const DeleteProject = ({
  projectName,
  id,
  isDelete,
  setIsDelete
}) => {
  const [deleteProject, {
    isSuccess: isDelSuccess,
    isError: isDelError,
    error: delerror
  }] = useDeleteProjectMutation();

  const onDelete = async () => {
    await deleteProject({ id });

    setIsDelete(false);
  };

  return (
    <CreateModal
      modalTitle={"Delete Project"}
      isOpen={isDelete}
      onClose={() => {
      setIsDelete(false);
      }}
      buttonPrimaryText={"Delete"}
      onSubmit={onDelete}
  >
      <Text
      fontSize={"18px"}
      >
        Apa kamu yakin ingin menghapus project {projectName}?
      </Text>
    </CreateModal>
  )
}

export default React.memo(DeleteProject)