import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import useTitle from '../../hooks/useTitle';
import { Box, Flex, Image, Stack, Text, List, ListItem, Alert, AlertIcon, Icon, useToast, Select } from '@chakra-ui/react';
import { pathFile } from '../../utils/Functions';
import { FaBell, FaUser } from 'react-icons/fa';
import { useSendLogoutMutation } from './authApiSlice';
import { HiDocumentReport } from 'react-icons/hi';
import { useGetTasksQuery } from '../tasks/tasksApiSlice';
import Chart from 'react-apexcharts';
import { statusIcon } from '../../utils/Constant';
import { useDeleteNotificationsAllMutation, useDeleteNotificationsByIdMutation, useGetNotificationsByIdQuery } from '../../utils/helper/notifications/notificationsApiSlice';
import { PulseLoader } from 'react-spinners';

const Welcome = () => {
    const [sendLogout, { isLoading, isSuccess, isError, error }] = useSendLogoutMutation();
    const {
        data: tasks,
        isSuccess: isTasksSuccess,
        isLoading: isTasksLoading,
        isError: isTasksError,
        error: errorTasks
      } = useGetTasksQuery("tasksList", {
        pollingInterval: 15000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
    });

    const navigate = useNavigate();
    const toast = useToast();

    const {
        id: idUser,
        name,
        email,
        image,
        isManager,
        isAdmin,
        isKoordinator,
    } = useAuth();

    const {
        data: notifications,
        isLoading: isNotificationsLoading,
        isSuccess: isNotificationsSuccess,
        isError: isNotificationsError,
        error: notificationsError,
      } = useGetNotificationsByIdQuery(idUser, {
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
      });
    
      const [deleteNotificationsAll, {}] = useDeleteNotificationsAllMutation();
      const [deleteNotificationsById, {}] = useDeleteNotificationsByIdMutation();

    useTitle(`Wika Engineering Report System ${name}`);

    const date = new Date();

    const getGreeting = () => {
        const hours = date.getHours();
        if (hours >= 0 && hours < 12) {
            return 'Selamat Pagi';
        } else if (hours >= 12 && hours < 15) {
            return 'Selamat Siang';
        } else if (hours >= 15 && hours < 18) {
            return 'Selamat Sore';
        } else {
            return 'Selamat Malam';
        }
    };

    const greeting = getGreeting();
    const dashboardImage = require('../../img/dash-bg.png');

    const handleLogout = async () => {
        await sendLogout();
        navigate("/");
    };

    const [showNotifications, setShowNotifications] = useState(false);

    const toggleNotifications = () => {
        setShowNotifications(!showNotifications);
    };

    const handleNotificationClearAll = async () => {
        await deleteNotificationsAll({ id: idUser });
    
        toast({
            title: "Success",
            description: "Semua notifications berhasil di hapus.",
            status: "success",
            position: 'top',
            duration: 9000,
            isClosable: true,
        });
      }
    
      const handleNotificationClearOne = async (notificationId) => {
        await deleteNotificationsById({ id: notificationId });
    
        toast({
            title: "Success",
            description: "Notifications berhasil di hapus.",
            status: "success",
            position: 'top',
            duration: 9000,
            isClosable: true,
        });
      }

    const [timeRange, setTimeRange] = useState('Last weeks');

    const handleTimeRangeChange = (event) => {
        setTimeRange(event.target.value);
    };

    const generateCategories = () => {
        const categories = [];
        const now = new Date();
        switch (timeRange) {
            case 'Last years':
                for (let i = 11; i >= 0; i--) {
                    const date = new Date(now.getFullYear(), now.getMonth() - i, 1);
                    categories.push(date.toISOString().split('T')[0]);
                }
                break;
            case 'Last 6 months':
                for (let i = 5; i >= 0; i--) {
                    const date = new Date(now.getFullYear(), now.getMonth() - i, 1);
                    categories.push(date.toISOString().split('T')[0]);
                }
                break;
            case 'Last 3 months':
                for (let i = 2; i >= 0; i--) {
                    const date = new Date(now.getFullYear(), now.getMonth() - i, 1);
                    categories.push(date.toISOString().split('T')[0]);
                }
                break;
            case 'Last month':
                for (let i = 30; i >= 0; i--) {
                    const date = new Date(now.getFullYear(), now.getMonth(), now.getDate() - i);
                    categories.push(date.toISOString().split('T')[0]);
                }
                break;
            case 'Last weeks':
                for (let i = 6; i >= 0; i--) {
                    const date = new Date(now.getFullYear(), now.getMonth(), now.getDate() - i);
                    categories.push(date.toISOString().split('T')[0]);
                }
                break;
            case 'Last 3 days':
                for (let i = 2; i >= 0; i--) {
                    const date = new Date(now.getFullYear(), now.getMonth(), now.getDate() - i);
                    categories.push(date.toISOString().split('T')[0]);
                }
                break;
            case 'Today':
                categories.push(now.toISOString().split('T')[0]);
                break;
            default:
                break;
        }
        return categories;
    };

    const categories = generateCategories();

    const filterTasksByStatus = (status) => {
        return categories.map((category) => {
            const date = new Date(category);
            return tasks?.ids?.filter((taskId) => {
                const task = tasks.entities[taskId];
                const taskDate = new Date(task.updatedAt);
                return task.statusTask === status && taskDate.getMonth() === date.getMonth() && taskDate.getFullYear() === date.getFullYear();
            }).length;
        });
    };

    const chartOptions = {
        chart: {
            type: 'area',
            height: 350,
            zoom: {
                enabled: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        xaxis: {
            type: 'datetime',
            categories: categories
        },
        tooltip: {
            x: {
                format: 'dd/MM/yy'
            }
        }
    };

    const chartSeries = [
        {
            name: 'Open',
            data: filterTasksByStatus('Open')
        },
        {
            name: 'Completed',
            data: filterTasksByStatus('Completed')
        },
        {
            name: 'Revisi',
            data: filterTasksByStatus('Revision I').concat(filterTasksByStatus('Revision II'))
        }
    ];

    if (isTasksLoading) {
        return (
            <PulseLoader color={"#2BACE3"} />
        );
    }

    if (isTasksSuccess) {
        const { ids, entities } = tasks;

        let filteredIds;
        if (isManager || isAdmin || isKoordinator) {
            filteredIds = [...ids];
        } else {
            filteredIds = ids?.filter(
                (taskId) => entities[taskId].assignRoles.some((taskUser) => taskUser.id === idUser)
            );
        }

        const openTasksCount = filteredIds?.filter((taskId) => entities[taskId].statusTask === "Open").length;
        const completedTasksCount = filteredIds?.filter((taskId) => entities[taskId].statusTask === "Completed").length;
        const revisiTasksCount = filteredIds?.filter((taskId) => ['Revision I', 'Revision II'].includes(entities[taskId].statusTask)).length;

        return (
            <Stack spacing={4} p={8}>
                <Stack
                    flexDirection={"row"}
                    alignSelf="flex-end"
                    mb={-16}
                    zIndex={999}
                    mr={6}
                    spacing={4}
                    position="relative"
                >
                    <Box position="relative">
                        <FaBell
                            size={24}
                            color="white"
                            onClick={toggleNotifications}
                            style={{ cursor: 'pointer' }}
                        />
                        {Array.isArray(notifications) && notifications && notifications.length > 0 && (
                            <Box
                                position="absolute"
                                top="-5px"
                                right="-5px"
                                bg="red"
                                color="white"
                                borderRadius="50%"
                                width="16px"
                                height="16px"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                fontSize="12px"
                            >
                                {notifications.length}
                            </Box>
                        )}
                    </Box>
                    {showNotifications && (
                        <Box
                            position="absolute"
                            top="2.5em"
                            right="5em"
                            bg="white"
                            boxShadow="md"
                            borderRadius="md"
                            p={4}
                            zIndex={1}
                            maxHeight="450px"
                            overflowY="auto"
                        >
                            <List spacing={3} width={580}>
                                <Stack
                                    flexDirection={"row"}
                                    borderBottom="1px solid #A1A1AA"
                                    pb={2}
                                    justifyContent={"space-between"}
                                >
                                    <Text fontSize={"md"} as="b">Notification</Text>
                                    <Text fontSize={"md"} color={'blue.500'} onClick={handleNotificationClearAll} cursor={"pointer"}>Clear All</Text>
                                </Stack>
                                {notifications.length > 0 ? notifications.map((notification, index) => (
                                    <ListItem key={index}>
                                        <Alert status={notification.status} variant="ghost">
                                            <Stack
                                                flexDirection={"row"}
                                                alignItems={"center"}
                                            >
                                                <AlertIcon
                                                    color={statusIcon(notification.status)}
                                                    boxSize={6}
                                                />
                                                <Flex
                                                    flexDirection={"row"}
                                                    justifyContent={"space-between"}
                                                    width={"100%"}
                                                >
                                                <Stack
                                                    spacing={0}
                                                    flexDir={"column"}
                                                    mr={2}
                                                >
                                                    <Text color="black" fontSize={"md"} as="b">{notification.title}</Text>
                                                    <Text color="black" fontSize={"sm"}>{notification.message}</Text>
                                                    <Text
                                                        color="gray.500"
                                                        fontSize={"xs"}
                                                        mt={1}
                                                    >
                                                        {notification.date}
                                                    </Text>
                                                </Stack>
                                                <Stack
                                                    flexDirection={"row"}
                                                >
                                                    <Text
                                                        fontSize={"md"}
                                                        color={"blue.500"}
                                                        onClick={() => handleNotificationClearOne(notification._id)}
                                                        cursor={"pointer"}
                                                    >
                                                        Clear
                                                    </Text>
                                                </Stack>
                                                </Flex>
                                            </Stack>
                                        </Alert>
                                    </ListItem>
                                )) : (
                                    <ListItem>
                                        <Text fontSize={"md"}>Tidak ada Notification</Text>
                                    </ListItem>
                                )}
                            </List>
                        </Box>
                    )}
                    <FaUser
                        size={24}
                        color="white"
                        onClick={handleLogout}
                        style={{ cursor: 'pointer' }}
                    />
                    <Text color={"white"} fontSize={"lg"} as="b" ml={-2} onClick={handleLogout} style={{ cursor: 'pointer' }}>Logout</Text>
                </Stack>
                <Flex
                    bgImage={`url(${dashboardImage})`}
                    bgSize="cover"
                    bgPosition="center"
                    bgRepeat="no-repeat"
                    p={4}
                    borderRadius="lg"
                    boxShadow="md"
                    color="white"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    height={300}
                />
                <Stack
                    alignSelf="center"
                    width={"95%"}
                    borderRadius="lg"
                >
                    <Flex
                        bg="white"
                        p={4}
                        mt={-16}
                        borderRadius="lg"
                        boxShadow="md"
                        color="black"
                        flexDirection="row"
                        height={113}
                        opacity={0.9}
                        backdropFilter={"blur(10px)"}
                    >
                        <Stack spacing={2} flexDirection={"row"}>
                            <Box
                                p={2}
                                boxSize={20}
                            >
                                <Image
                                    src={pathFile(image)}
                                    alt="Profile"
                                    borderRadius="16px"
                                />
                            </Box>
                            <Stack
                                spacing={0}
                                justifyContent="center"
                            >
                                <Text fontSize="lg" fontWeight="bold">{greeting}, {name}</Text>
                                <Text fontSize="md">{email}</Text>
                            </Stack>
                        </Stack>
                    </Flex>
                </Stack>
                <Stack
                    flexDirection={"row"}
                    mt={8}
                    justifyContent="space-between"
                >
                    <Stack
                        flexDirection={"row"}
                        bg="white"
                        p={4}
                        borderRadius="xl"
                        alignItems="center"
                        boxShadow="0px 4px 4px #0000001A"
                    >
                        <Stack
                            spacing={0}
                            justifyContent="center"
                            width={180}
                        >
                            <Text color={"#A0AEC0"} fontSize={"12px"}>Report Open</Text>
                            <Text fontSize="20px" fontWeight="bold">{openTasksCount}</Text>
                        </Stack>
                        <Icon
                            as={HiDocumentReport}
                            boxSize={14}
                            color="white"
                            bg="blue.500"
                            borderRadius="lg"
                            p={3}
                        />
                    </Stack>
                    <Stack
                        flexDirection={"row"}
                        bg="white"
                        p={4}
                        borderRadius="xl"
                        alignItems="center"
                        boxShadow="0px 4px 4px #0000001A"
                    >
                        <Stack
                            spacing={0}
                            justifyContent="center"
                            width={180}
                        >
                            <Text color={"#A0AEC0"} fontSize={"12px"}>Report Completed</Text>
                            <Text fontSize="20px" fontWeight="bold">{completedTasksCount}</Text>
                        </Stack>
                        <Icon
                            as={HiDocumentReport}
                            boxSize={14}
                            color="white"
                            bg="green.500"
                            borderRadius="lg"
                            p={3}
                        />
                    </Stack>
                    <Stack
                        flexDirection={"row"}
                        bg="white"
                        p={4}
                        borderRadius="xl"
                        alignItems="center"
                        boxShadow="0px 4px 4px #0000001A"
                    >
                        <Stack
                            spacing={0}
                            justifyContent="center"
                            width={180}
                        >
                            <Text color={"#A0AEC0"} fontSize={"12px"}>Report Revisi</Text>
                            <Text fontSize="20px" fontWeight="bold">{revisiTasksCount}</Text>
                        </Stack>
                        <Icon
                            as={HiDocumentReport}
                            boxSize={14}
                            color="white"
                            bg="orange.500"
                            borderRadius="lg"
                            p={3}
                        />
                    </Stack>
                    <Stack
                        flexDirection={"row"}
                        bg="white"
                        p={4}
                        borderRadius="xl"
                        alignItems="center"
                        boxShadow="0px 4px 4px #0000001A"
                    >
                        <Stack
                            spacing={0}
                            justifyContent="center"
                            width={180}
                        >
                            <Text color={"#A0AEC0"} fontSize={"12px"}>Task Close</Text>
                            <Text fontSize="20px" fontWeight="bold">{completedTasksCount}</Text>
                        </Stack>
                        <Icon
                            as={HiDocumentReport}
                            boxSize={14}
                            color="white"
                            bg="orange.500"
                            borderRadius="lg"
                            p={3}
                        />
                    </Stack>
                    <Stack
                        flexDirection={"row"}
                        bg="white"
                        p={4}
                        borderRadius="xl"
                        alignItems="center"
                        boxShadow="0px 4px 4px #0000001A"
                    >
                        <Stack
                            spacing={0}
                            justifyContent="center"
                            width={180}
                        >
                            <Text color={"#A0AEC0"} fontSize={"12px"}>Task Open</Text>
                            <Text fontSize="20px" fontWeight="bold">{openTasksCount}</Text>
                        </Stack>
                        <Icon
                            as={HiDocumentReport}
                            boxSize={14}
                            color="white"
                            bg="orange.500"
                            borderRadius="lg"
                            p={3}
                        />
                    </Stack>
                </Stack>
                <Box mt={8} width="100%">
                    <Flex justifyContent="flex-end" mb={4}>
                        <Select value={timeRange} onChange={handleTimeRangeChange} width="200px">
                            <option value="Last years">Last years</option>
                            <option value="Last 6 months">Last 6 months</option>
                            <option value="Last 3 months">Last 3 months</option>
                            <option value="Last month">Last month</option>
                            <option value="Last weeks">Last weeks</option>
                            <option value="Last 3 days">Last 3 days</option>
                            <option value="Today">Today</option>
                        </Select>
                    </Flex>
                    <Chart
                        options={chartOptions}
                        series={chartSeries}
                        type="area"
                        height={350}
                    />
                </Box>
            </Stack>
        );
    }

    return (
        <Stack spacing={4} p={8}>
            <Stack
                flexDirection={"row"}
                alignSelf="flex-end"
                mb={-16}
                zIndex={999}
                mr={6}
                spacing={4}
                position="relative"
            >
                <Box position="relative">
                    <FaBell
                        size={24}
                        color="white"
                        onClick={toggleNotifications}
                        style={{ cursor: 'pointer' }}
                    />
                </Box>
                <FaUser
                    size={24}
                    color="white"
                    onClick={handleLogout}
                    style={{ cursor: 'pointer' }}
                />
                <Text color={"white"} fontSize={"lg"} as="b" ml={-2} onClick={handleLogout} style={{ cursor: 'pointer' }}>Logout</Text>
            </Stack>
            <Flex
                bgImage={`url(${dashboardImage})`}
                bgSize="cover"
                bgPosition="center"
                bgRepeat="no-repeat"
                p={4}
                borderRadius="lg"
                boxShadow="md"
                color="white"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height={300}
            />
            <Stack
                alignSelf="center"
                width={"95%"}
                borderRadius="lg"
            >
                <Flex
                    bg="white"
                    p={4}
                    mt={-16}
                    borderRadius="lg"
                    boxShadow="md"
                    color="black"
                    flexDirection="row"
                    height={113}
                    opacity={0.9}
                    backdropFilter={"blur(10px)"}
                >
                    <Stack spacing={2} flexDirection={"row"}>
                        <Box
                            p={2}
                            boxSize={20}
                        >
                            <Image
                                src={pathFile(image)}
                                alt="Profile"
                                borderRadius="16px"
                            />
                        </Box>
                        <Stack
                            spacing={0}
                            justifyContent="center"
                        >
                            <Text fontSize="lg" fontWeight="bold">{greeting}, {name}</Text>
                            <Text fontSize="md">{email}</Text>
                        </Stack>
                    </Stack>
                </Flex>
            </Stack>
            <Stack
                flexDirection={"row"}
                mt={8}
                justifyContent="space-between"
            >
                <Stack
                    flexDirection={"row"}
                    bg="white"
                    p={4}
                    borderRadius="xl"
                    alignItems="center"
                    boxShadow="0px 4px 4px #0000001A"
                >
                    <Stack
                        spacing={0}
                        justifyContent="center"
                        width={180}
                    >
                        <Text color={"#A0AEC0"} fontSize={"12px"}>Report Open</Text>
                        <Text fontSize="20px" fontWeight="bold">0</Text>
                    </Stack>
                    <Icon
                        as={HiDocumentReport}
                        boxSize={14}
                        color="white"
                        bg="blue.500"
                        borderRadius="lg"
                        p={3}
                    />
                </Stack>
                <Stack
                    flexDirection={"row"}
                    bg="white"
                    p={4}
                    borderRadius="xl"
                    alignItems="center"
                    boxShadow="0px 4px 4px #0000001A"
                >
                    <Stack
                        spacing={0}
                        justifyContent="center"
                        width={180}
                    >
                        <Text color={"#A0AEC0"} fontSize={"12px"}>Report Completed</Text>
                        <Text fontSize="20px" fontWeight="bold">0</Text>
                    </Stack>
                    <Icon
                        as={HiDocumentReport}
                        boxSize={14}
                        color="white"
                        bg="green.500"
                        borderRadius="lg"
                        p={3}
                    />
                </Stack>
                <Stack
                    flexDirection={"row"}
                    bg="white"
                    p={4}
                    borderRadius="xl"
                    alignItems="center"
                    boxShadow="0px 4px 4px #0000001A"
                >
                    <Stack
                        spacing={0}
                        justifyContent="center"
                        width={180}
                    >
                        <Text color={"#A0AEC0"} fontSize={"12px"}>Report Revisi</Text>
                        <Text fontSize="20px" fontWeight="bold">0</Text>
                    </Stack>
                    <Icon
                        as={HiDocumentReport}
                        boxSize={14}
                        color="white"
                        bg="orange.500"
                        borderRadius="lg"
                        p={3}
                    />
                </Stack>
                <Stack
                    flexDirection={"row"}
                    bg="white"
                    p={4}
                    borderRadius="xl"
                    alignItems="center"
                    boxShadow="0px 4px 4px #0000001A"
                >
                    <Stack
                        spacing={0}
                        justifyContent="center"
                        width={180}
                    >
                        <Text color={"#A0AEC0"} fontSize={"12px"}>Task Close</Text>
                        <Text fontSize="20px" fontWeight="bold">0</Text>
                    </Stack>
                    <Icon
                        as={HiDocumentReport}
                        boxSize={14}
                        color="white"
                        bg="orange.500"
                        borderRadius="lg"
                        p={3}
                    />
                </Stack>
                <Stack
                    flexDirection={"row"}
                    bg="white"
                    p={4}
                    borderRadius="xl"
                    alignItems="center"
                    boxShadow="0px 4px 4px #0000001A"
                >
                    <Stack
                        spacing={0}
                        justifyContent="center"
                        width={180}
                    >
                        <Text color={"#A0AEC0"} fontSize={"12px"}>Task Open</Text>
                        <Text fontSize="20px" fontWeight="bold">0</Text>
                    </Stack>
                    <Icon
                        as={HiDocumentReport}
                        boxSize={14}
                        color="white"
                        bg="orange.500"
                        borderRadius="lg"
                        p={3}
                    />
                </Stack>
            </Stack>
        </Stack>
    );
};

export default Welcome;